import React, { useEffect } from 'react';

import { CustomFooter as Footer } from './Footer';
import { Layout } from 'antd';
import TopBar from './TopBar';
import { notify } from '../utils/notifications';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useReferrer } from '../utils/referrer';
const { Header, Content } = Layout;

export default function BasicLayout({ children }) {
  const { refCode, setRefCode, allowRefLink } = useReferrer();
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 800px)',
  });

  useEffect(() => {
    if (!!parsed.refCode && parsed.refCode !== refCode && allowRefLink) {
      notify({ message: `New referrer ${parsed.refCode} added` });
      setRefCode(parsed.refCode);
    }
  }, [parsed, refCode, setRefCode, allowRefLink]);

  return (
    <React.Fragment>
      <Layout
        style={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          ...(!isDesktopOrLaptop ? { zoom: '0.5' } : {}),
        }}
      >
        <Header style={{ padding: 0, minHeight: 64, height: 'unset' }}>
          <TopBar />
        </Header>
        <Content style={{ flex: 1 }}>{children}</Content>
        <Footer />
      </Layout>
    </React.Fragment>
  );
}
