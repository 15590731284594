import { useEffect, useState } from 'react';

import React from 'react';
import { ethers } from 'ethers';
import styled from 'styled-components';

// import React from 'react';

declare const window: any;

// const SmartContracts = async () => {
//   // console.log('SmartContracts', ethers.getSigners());

//   // const [haveMetamask, sethaveMetamask] = useState(true);

//   // const [client, setclient] = useState({
//   //   isConnected: false,
//   // });

//   // const checkConnection = async () => {
//   //   const { ethereum } = window;
//   //   if (ethereum) {
//   //     sethaveMetamask(true);
//   //     const accounts = await ethereum.request({ method: 'eth_accounts' });
//   //     if (accounts.length > 0) {
//   //       setclient({
//   //         isConnected: true,
//   //         address: accounts[0],
//   //       });
//   //     } else {
//   //       setclient({
//   //         isConnected: false,
//   //       });
//   //     }
//   //   } else {
//   //     sethaveMetamask(false);
//   //   }
//   // };

//   // const provider = new ethers.providers.Web3Provider(window.ethereum);
//   // await provider.send('eth_requestAccounts', []);

//   console.log('123');
//   return <Wrapper>smart contracts</Wrapper>;
// };

// export default SmartContracts;
// https://docs.ethers.io/v5/getting-started/
function SmartContracts() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const test = async () => {
    await provider.send('eth_requestAccounts', []);
  };

  test();
  const signer = provider.getSigner();
  console.log(signer);
  const tx = signer.sendTransaction({
    to: '0x9bbCAcef65947493fB84811caBa5e66484Fda79e',
    value: ethers.utils.parseEther('0.01'),
  });

  const daiAddress = "dai.tokens.ethers.eth";

  const daiAbi = [
    // Some details about the token
    "function name() view returns (string)",
    "function symbol() view returns (string)",
  
    // Get the account balance
    "function balanceOf(address) view returns (uint)",
  
    // Send some of your tokens to someone else
    "function transfer(address to, uint amount)",
  
    // An event triggered whenever anyone transfers to someone else
    "event Transfer(address indexed from, address indexed to, uint amount)"
  ];
  
  const daiContract = new ethers.Contract(daiAddress, daiAbi, provider);

  return <div>SmartContracts</div>;
}

export default SmartContracts;
