export const helpUrls = {
  customerSupport: 'https://t.me/ProjectSerum',
  customerSupportZh: 'https://t.me/ProjectSerum_Chinese',
  contactEmail: 'mailto:contact@projectserum.com',
  discord: 'https://discord.gg/EDvudv6',
  telegram: 'https://t.me/ProjectSerum',
  github: 'https://github.com/project-serum',
  projectSerum: 'https://projectserum.com/',
  developerResources: 'https://projectserum.com/developer-resources',
  solanaBeach: 'https://solanabeach.io',
  rocketship: 'https://rship.us',
  medium:
    'https://balancesoft.medium.com/how-to-use-to-balancesoft-to-send-and-receive-coin-payments-via-shareable-links-fcdfbd065683',
  telegramCustomerService: 'https://telegram.me/getbalancesoft',
  career:
    'https://docs.google.com/forms/d/e/1FAIpQLSeKAstb5K3YL5qGqfkvuDbytH6KCIe37TRnKIvLk28rbSPktg/viewform?usp=sf_link',
  documentation: 'https://docs.getbalancesoft.com',
};
