import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Collapse,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  Result,
  Select,
  Skeleton,
  Statistic,
  Steps,
  Typography,
  message,
} from 'antd';
import { POPULAR_FIAT, POPULAR_TOKENS } from '../utils/tokenTypes';
import React, { useEffect, useState } from 'react';
import {
  findAssociatedTokenAddress,
  nativeTransfers,
  transferTokens,
} from '../utils/tokensSollet';
import { useHistory, useParams } from 'react-router-dom';

import { CheckCircleFilled } from '@ant-design/icons';
import FloatingElement from '../components/layout/FloatingElement';
import { PublicKey } from '@solana/web3.js';
import apiClient from '../utils/apiClient';
import { notify } from '../utils/notifications';
import styled from 'styled-components';
import { useConnection } from '../utils/connection';
import { useWallet } from '../utils/wallet';

const { TextArea } = Input;
const { Paragraph, Text, Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;
const { Option } = Select;

const { Countdown } = Statistic;

const Wrapper = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
`;

interface IPaymentData {
  key: string | null;
  id: string | null;
  coin: string | null;
  date_created: number | null;
  date_due: number | null;
  quantity: number | null;
  address: string | null;
  status: string | null;
  memo: string | null;
  public_key: string | null;

  payment_type: string | null;
  shipping_address: string | null;
  inventory: number | null;
  recurring_payments: object | null;
}

export default function MakeInstantPaymentPage() {
  const connection = useConnection();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();

  const { wallet, connected } = useWallet();
  const [walletKey, setWalletKey] = useState<any>();
  const [paymentData, setPaymentData] = useState<IPaymentData | any>();
  const [paymentQuantity, setPaymentQuantity] = useState<IPaymentData | any>(
    null,
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isLoadingFetchPrice, setIsLoadingFetchPrice] = useState<boolean>(
    false,
  );

  const [selectChain, setSelectChain] = useState<any>('solana');
  const [selectCoin, setSelectCoin] = useState<any>(null);
  const [convertedPrice, setConvertedPrice] = useState<any>(null);
  const [isPaymentExpired, setIsPaymentExpired] = useState<any>(false);
  const [deadline, setDeadline] = useState<any>(null);

  const [current, setCurrent] = React.useState(0);

  useEffect(() => {
    if (!isLoaded && id !== undefined && id !== '') {
      onSearch(id);
    }
  });

  const fetchDataExternal = () => {
    try {
      apiClient
        .get(`/public_payment_request`, { params: { id: id } })
        .then((responseObject) => {
          if (responseObject?.status === 200) {
            setIsLoaded(true);
            setPaymentData(responseObject?.data?.data);
            if (responseObject?.data?.data?.status === 'PAID') {
              setCurrent(3);
            }
          }
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setWalletKey(wallet?.publicKey?.toBase58());
    if (!isLoaded && id !== undefined && id !== '') {
      fetchDataExternal();
    }
    // eslint-disable-next-line
  }, [wallet, connected]);

  const onSetDeadline = () => {
    setDeadline(Date.now() + 1 * 60000);
  };

  const onSearch = (value) => {
    try {
      apiClient
        .get(`/public_payment_request`, { params: { id: value } })
        .then((responseObject) => {
          if (responseObject?.status === 200) {
            setIsLoaded(true);
            setPaymentData(responseObject?.data?.data);
            history.replace(`/pay/${value}`);
          }
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const fetchTickerPrice = () => {
    try {
      let sell_token_yf_symbol = POPULAR_FIAT.filter(
        (i) => i.code === paymentData?.price_token.toUpperCase(),
      ).map((i) => i.yfSymbol)[0];
      if (sell_token_yf_symbol == null) {
        sell_token_yf_symbol = POPULAR_TOKENS.filter(
          (i) => i.tokenSymbol === paymentData?.price_token.toUpperCase(),
        ).map((i) => i.yfSymbol)[0];
      }
      const pay_token_yf_symbol = POPULAR_TOKENS.filter(
        (i) => i.tokenSymbol === selectCoin,
      ).map((i) => i.yfSymbol)[0];

      setIsLoadingFetchPrice(true);

      apiClient
        .get(`/fetch_ticker_price`, {
          params: {
            sell_token_symbol: sell_token_yf_symbol,
            pay_token_symbol: pay_token_yf_symbol,
            price_quantity: paymentData?.price_quantity,
          },
        })
        .then((responseObject) => {
          if (responseObject.status === 200) {
            setConvertedPrice(responseObject?.data?.data);
            setIsLoadingFetchPrice(false);
            onSetDeadline();
            setIsPaymentExpired(false);
          }
        });
    } catch (_e) {
      let e: any = _e;
      console.log(e);
      setIsLoadingFetchPrice(false);
    }
  };

  async function onMakePayment(wallet, options) {
    if (!wallet || !connected) {
      notify({
        message: 'Wallet not connected',
        description: 'Connect your Solana wallet to Balancesoft',
        type: 'error',
      });
      return;
    }

    let coin = convertedPrice?.is_same_token
      ? paymentData?.price_token
      : options.coin;
    let quantity = convertedPrice?.is_same_token
      ? paymentData?.price_quantity
      : options.quantity;

    if (paymentData?.type === 'fund_raising') {
      coin = selectCoin;
      quantity = paymentQuantity;
    }

    paymentData.token_symbol = coin;
    paymentData.quantity = quantity;

    const decimal = POPULAR_TOKENS.filter((i) => i.tokenSymbol === coin).map(
      (i) => i.decimal,
    )[0];
    const mint = POPULAR_TOKENS.filter((i) => i.tokenSymbol === coin).map(
      (i) => i.mintAddress,
    )[0];
    const amount = quantity * Math.pow(10, decimal);

    if (coin === 'SOL') {
      // native sol transfer
      try {
        const txid = await nativeTransfers(
          connection,
          wallet,
          paymentData?.solana_wallet_address,
          amount,
        );
        paymentData.txid = txid;
        paymentData.user_email = options.user_email;
        paymentData.user_message = options.user_message;
        paymentData.user_name = options.user_name;

        paymentData.shipping_address = options.shipping_address;
        paymentData.payer_wallet = wallet?.publicKey?.toBase58();

        apiClient
          .post(`/public_payment_request`, paymentData)
          .then((responseObject) => {
            if (responseObject.status === 200) {
              if (paymentData?.type === 'one_time') {
                setIsPaid(true);
              }
              fetchDataExternal();
              setCurrent(current + 1);
              if (paymentData?.redirect_link) {
                window.open(`//${paymentData?.redirect_link}`, '_blank');
              }
            }
          });
        notify({
          message: 'Transaction Sent',
          type: 'success',
          txid,
        });
      } catch (_e) {
        let e: any = _e;
        notify({
          message: 'Error making instant payment',
          description: e.message,
          type: 'error',
        });
        return;
      }
    } else {
      try {
        let txid;
        const sourcePublicKey = await findAssociatedTokenAddress(
          wallet.publicKey,
          new PublicKey(mint),
        );

        txid = await transferTokens({
          connection: connection,
          owner: wallet,
          sourcePublicKey: sourcePublicKey,
          destinationPublicKey: new PublicKey(
            paymentData?.solana_wallet_address,
          ),
          amount: amount,
          memo: null,
          mint: new PublicKey(mint),
          decimals: decimal,
          overrideDestinationCheck: true,
        });

        paymentData.txid = txid;
        paymentData.user_email = options.user_email;
        paymentData.user_message = options.user_message;
        paymentData.user_name = options.user_name;

        paymentData.shipping_address = options.shipping_address;
        paymentData.payer_wallet = wallet?.publicKey?.toBase58();

        apiClient
          .post(`/public_payment_request`, paymentData)
          .then((responseObject) => {
            if (responseObject?.status === 200) {
              if (paymentData?.type === 'one_time') {
                setIsPaid(true);
              }
              fetchDataExternal();
              setCurrent(current + 1);
              if (paymentData?.redirect_link) {
                window.open(`//${paymentData?.redirect_link}`, '_blank');
              }
            }
          });
        notify({
          message: 'Transaction Sent',
          type: 'success',
          txid,
        });
      } catch (_e) {
        let e: any = _e;
        notify({
          message: 'Error making instant payment',
          description: e.message,
          type: 'error',
        });
        return;
      }
    }
  }

  const onFinish = (values: any) => {
    const user_email = values.user_email;
    const user_message = values.user_message;
    const user_name = values.user_name;

    const shipping_address = values.shipping_address;
    const coin = selectCoin;
    const quantity = convertedPrice?.converted_pay_price;

    onMakePayment(wallet, {
      user_name,
      user_email,
      user_message,
      shipping_address,
      quantity,
      coin,
    });
  };

  const steps = [
    {
      title: 'Chain',
      content: (
        <div>
          <Title level={5}>Payment</Title>
          <Paragraph style={{ marginTop: '15px' }}>
            <Text type="secondary">
              <small>
                You can choose what chain and what stablecoin / non-stablecoin
                to pay from next.
              </small>
            </Text>
          </Paragraph>
          <Form initialValues={{ blockchain: 'solana' }}>
            <Form.Item label="Blockchain to pay from" name="blockchain">
              <Select
                style={{ width: 200 }}
                onChange={(e) => setSelectChain(e)}
              >
                <Option value="solana">Solana</Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
      ),
      description: (
        <Text style={{ textTransform: 'capitalize' }} type="secondary">
          {selectChain}
        </Text>
      ),
    },
    {
      title: 'Coin',
      content: (
        <div>
          <Title level={5}>Payment</Title>
          <Paragraph style={{ marginTop: '15px' }}>
            <Text type="secondary">
              <small>
                Balancesoft will calculate the equivalent conversion rate for
                the selected coin.
              </small>
            </Text>
          </Paragraph>
          <Form>
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text>Coin to pay in</Text>
              <Form.Item name="coin_type">
                <Select
                  placeholder="Select coin"
                  allowClear
                  onChange={(e) => setSelectCoin(e)}
                  style={{ width: '10rem' }}
                >
                  {POPULAR_TOKENS.filter((i) =>
                    paymentData?.coins_to_accept.includes(
                      i.tokenSymbol.toLowerCase(),
                    ),
                  ).map((i) => (
                    <React.Fragment key={i.mintAddress}>
                      {
                        <Option key={i.mintAddress} value={i.tokenSymbol}>
                          <Avatar
                            src={
                              <Image
                                preview={false}
                                style={{ width: '1rem' }}
                                src={i.icon}
                              />
                            }
                          />
                          &nbsp;
                          {i.tokenSymbol}
                        </Option>
                      }
                    </React.Fragment>
                  ))}
                </Select>
              </Form.Item>
            </span>

            {paymentData?.type === 'fund_raising' ? (
              <span
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Text>No. of Coin (Price)</Text>
                <Form.Item
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: 'Please input quantity of coins.',
                    },
                  ]}
                  style={{ width: '10rem' }}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    onChange={(e) => setPaymentQuantity(e)}
                  />
                </Form.Item>
              </span>
            ) : null}
          </Form>
        </div>
      ),
      description: (
        <div>
          <Avatar
            src={
              <Image
                preview={false}
                src={
                  POPULAR_TOKENS.filter(
                    (i) => i.tokenSymbol === selectCoin,
                  ).map((i) => i.icon)[0]
                }
                style={{ width: '1rem' }}
              />
            }
          />
          &nbsp;
          <Text style={{ textTransform: 'capitalize' }} type="secondary">
            {
              POPULAR_TOKENS.filter((i) => i.tokenSymbol === selectCoin).map(
                (i) => i.tokenSymbol,
              )[0]
            }
          </Text>
        </div>
      ),
    },
    {
      title: 'Pay',
      content: (
        <div>
          <Title level={5}>Payment</Title>
          {convertedPrice?.is_same_token ? (
            <React.Fragment>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>You will pay</Text>
                <Text code>
                  <Image
                    preview={false}
                    style={{ width: '12px', marginRight: '5px' }}
                    src={
                      POPULAR_TOKENS.filter(
                        (i) => i.tokenSymbol === selectCoin,
                      ).map((i) => i.icon)[0]
                    }
                  />
                  {paymentData?.type === 'fund_raising'
                    ? selectCoin
                    : paymentData?.price_token}{' '}
                  {paymentData?.type === 'fund_raising'
                    ? paymentQuantity
                    : paymentData?.price_quantity}
                </Text>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>You will pay</Text>
                {convertedPrice?.converted_pay_price ? (
                  <Text code>
                    <Image
                      preview={false}
                      style={{ width: '12px', marginRight: '5px' }}
                      src={
                        POPULAR_TOKENS.filter(
                          (i) => i.tokenSymbol === selectCoin,
                        ).map((i) => i.icon)[0]
                      }
                    />
                    {selectCoin} {convertedPrice?.converted_pay_price}
                  </Text>
                ) : (
                  'Fetch price again'
                )}
              </p>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Make payment within countdown</Text>
                <span style={{ textAlign: 'right' }}>
                  {isPaymentExpired ? (
                    <Text>
                      Price has expired{' '}
                      <Button type="link" onClick={() => fetchTickerPrice()}>
                        Refresh Price
                      </Button>
                    </Text>
                  ) : (
                    <Countdown
                      value={deadline}
                      format="s"
                      suffix="secs"
                      valueStyle={{ fontSize: 'inherit', textAlign: 'right' }}
                      onFinish={() => setIsPaymentExpired(true)}
                    />
                  )}
                </span>
              </p>
            </React.Fragment>
          )}
        </div>
      ),
      description: null,
    },
    {
      title: 'Done',
      content: (
        <div style={{ marginBottom: '15px' }}>
          <Result
            status="success"
            title="Payment Made"
            subTitle="You may close this window now."
            extra={
              paymentData?.shopify_order_id
                ? [
                    <a href={paymentData?.shopify_order_status_link}>
                      <Button type="primary" key="console">
                        Return to Shopify
                      </Button>
                    </a>,
                  ]
                : null
            }
          />
        </div>
      ),
      description: null,
    },
  ];

  const next = () => {
    if (current + 1 === 2) {
      if (selectCoin == null) {
        message.error('Select a coin to pay in');
        return;
      }

      if (paymentData?.type === 'fund_raising') {
        setConvertedPrice({ is_same_token: true });
        setCurrent(current + 1);
      }

      if (paymentData?.type !== 'fund_raising') {
        if (paymentData?.price_token === selectCoin) {
          setConvertedPrice({ is_same_token: true });
          setCurrent(current + 1);
        } else {
          try {
            let sell_token_yf_symbol = POPULAR_FIAT.filter(
              (i) => i.code === paymentData?.price_token.toUpperCase(),
            ).map((i) => i.yfSymbol)[0];
            if (sell_token_yf_symbol == null) {
              sell_token_yf_symbol = POPULAR_TOKENS.filter(
                (i) => i.tokenSymbol === paymentData?.price_token.toUpperCase(),
              ).map((i) => i.yfSymbol)[0];
            }
            const pay_token_yf_symbol = POPULAR_TOKENS.filter(
              (i) => i.tokenSymbol === selectCoin,
            ).map((i) => i.yfSymbol)[0];

            setIsLoadingFetchPrice(true);

            apiClient
              .get(`/fetch_ticker_price`, {
                params: {
                  sell_token_symbol: sell_token_yf_symbol,
                  pay_token_symbol: pay_token_yf_symbol,
                  price_quantity: paymentData?.price_quantity,
                },
              })
              .then((responseObject) => {
                if (responseObject.status === 200) {
                  setConvertedPrice(responseObject?.data?.data);
                  setCurrent(current + 1);
                  setIsLoadingFetchPrice(false);
                  onSetDeadline();
                }
              });
          } catch (_e) {
            let e: any = _e;
            console.log(e);
            setIsLoadingFetchPrice(false);
          }
        }
      }
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  let tokenAvatar = POPULAR_TOKENS.filter(
    (i) => i.tokenSymbol === paymentData?.price_token,
  ).map((i) => i.icon)[0];
  if (tokenAvatar == null) {
    tokenAvatar = POPULAR_FIAT.filter(
      (i) => i.code === paymentData?.price_token,
    ).map((i) => i.icon)[0];
  }

  let nativeTokenAvatar = POPULAR_TOKENS.filter(
    (i) => i.tokenSymbol === paymentData?.native_price_token,
  ).map((i) => i.icon)[0];
  if (nativeTokenAvatar == null) {
    nativeTokenAvatar = POPULAR_FIAT.filter(
      (i) => i.code === paymentData?.native_price_token,
    ).map((i) => i.icon)[0];
  }

  return (
    <>
      <Wrapper>
        <FloatingElement>
          {isLoaded ? (
            <>
              <Form form={form} onFinish={onFinish}>
                <Card
                  title="Make Crypto Payment"
                  extra={
                    <React.Fragment>
                      {paymentData?.status === 'UNPAID' &&
                      paymentData?.type !== 'recurring' &&
                      paymentData?.type !== 'fund_raising' ? (
                        <Badge count={`Unpaid`} />
                      ) : null}
                      {paymentData?.status === 'PAID' &&
                      paymentData?.type !== 'recurring' &&
                      paymentData?.type !== 'fund_raising' ? (
                        <Badge
                          count={`Paid`}
                          style={{ backgroundColor: '#52c41a' }}
                        />
                      ) : null}
                    </React.Fragment>
                  }
                >
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        description={item.description}
                      />
                    ))}
                  </Steps>

                  <Card>
                    <Title level={5}>Details</Title>

                    {paymentData?.title ? (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Item Title</Text>
                        <Text code>{paymentData?.title}</Text>
                      </p>
                    ) : null}

                    {paymentData?.shopify_order_id ? (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Shopify Order Id</Text>
                        <Text code>{paymentData?.shopify_order_id}</Text>
                      </p>
                    ) : null}
                    {paymentData?.shopify_order_status_link ? (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Shopify Order Status</Text>
                        <Text
                          code
                          style={{
                            textAlign: 'right',
                            overflowWrap: 'anywhere',
                          }}
                        >
                          <a
                            href={paymentData?.shopify_order_status_link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Link
                          </a>
                        </Text>
                      </p>
                    ) : null}

                    {paymentData?.native_price_token ? (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Original CCY Cost</Text>
                        <Text code>
                          <Image
                            preview={false}
                            style={{ width: '12px', marginRight: '5px' }}
                            src={nativeTokenAvatar}
                          />
                          {paymentData?.native_price_token}{' '}
                          {paymentData?.native_price_quantity}
                        </Text>
                      </p>
                    ) : null}

                    {paymentData?.price_token ? (
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Cost</Text>
                        <Text code>
                          <Image
                            preview={false}
                            style={{ width: '12px', marginRight: '5px' }}
                            src={tokenAvatar}
                          />
                          {paymentData?.price_token}{' '}
                          {paymentData?.price_quantity}
                        </Text>
                      </p>
                    ) : null}

                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text>Address to Pay</Text>
                      <Text
                        code
                        style={{ textAlign: 'right', overflowWrap: 'anywhere' }}
                      >
                        <a
                          href={
                            'https://explorer.solana.com/address/' +
                            paymentData?.solana_wallet_address
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {paymentData?.solana_wallet_address}
                        </a>
                      </Text>
                    </p>
                  </Card>

                  <Card>
                    {steps[current].content}
                    {current < steps.length - 1 && current !== 2 && (
                      <Button
                        type="primary"
                        loading={isLoadingFetchPrice}
                        onClick={() => next()}
                      >
                        Next
                      </Button>
                    )}
                    {current < steps.length - 1 &&
                      current === 2 &&
                      (paymentData?.type === 'recurring' ||
                      paymentData?.type === 'fund_raising' ? (
                        <span>
                          <Button
                            disabled={isPaymentExpired}
                            loading={isLoadingFetchPrice}
                            type="primary"
                            htmlType="submit"
                          >
                            {paymentData?.type === 'recurring' &&
                            paymentData?.type === 'fund_raising'
                              ? 'Make Payment / Pay Again'
                              : 'Make Payment'}
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <Button
                            disabled={
                              paymentData?.date_paid ||
                              isPaid ||
                              isPaymentExpired
                            }
                            type="primary"
                            htmlType="submit"
                            loading={isLoadingFetchPrice}
                          >
                            {paymentData?.date_paid ? 'Paid' : 'Make Payment'}
                          </Button>
                        </span>
                      ))}

                    {current > 0 && current !== 3 && (
                      <Button
                        style={{ margin: '0 8px' }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                  </Card>

                  <Collapse
                    defaultActiveKey={['1']}
                    style={{ marginBottom: '15px', marginTop: '30px' }}
                  >
                    <Panel header="More Payments Details" key="1">
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Request Id</Text>
                        <Text
                          code
                          style={{
                            textAlign: 'right',
                            overflowWrap: 'anywhere',
                          }}
                        >
                          {paymentData?.id}
                        </Text>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Payment Type</Text>
                        <Text
                          code
                          style={{
                            textAlign: 'right',
                            overflowWrap: 'anywhere',
                          }}
                        >
                          {paymentData?.type}
                        </Text>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Created</Text>
                        <Text code>{paymentData?.date_created}</Text>
                      </p>

                      {paymentData?.image_url ? (
                        <p
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Image
                            width={200}
                            style={{ borderRadius: '15px' }}
                            src={paymentData?.image_url}
                          />
                        </p>
                      ) : null}
                      {paymentData?.company_name_displayed ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Merchant Name</Text>
                          <Text code>
                            {paymentData?.company_name_displayed}
                          </Text>
                        </p>
                      ) : null}
                      {paymentData?.company_address_displayed ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Merchant Address</Text>
                          <Text code>
                            {paymentData?.company_address_displayed}
                          </Text>
                        </p>
                      ) : null}
                      {paymentData?.verified_business === true ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Verified Business</Text>
                          <CheckCircleFilled style={{ color: '#1DA1F2' }} />
                        </p>
                      ) : null}
                      {paymentData?.redirect_link ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Redirect After Successful Payment</Text>
                          <Text code>{paymentData?.redirect_link}</Text>
                        </p>
                      ) : null}
                      {paymentData?.date_due ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Due</Text>
                          <Text code>
                            {paymentData?.date_due
                              ? paymentData?.date_due
                              : '-'}
                          </Text>
                        </p>
                      ) : null}

                      {paymentData?.memo ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Merchant Memo</Text>
                          <Text style={{ textAlign: 'right' }}>
                            {paymentData?.memo}
                          </Text>
                        </p>
                      ) : null}
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: 0,
                          marginBottom: '1em',
                        }}
                      >
                        <Text>Link</Text>
                        <Text
                          copyable={{
                            text:
                              process.env.NODE_ENV === 'development'
                                ? `${process.env.REACT_APP_SHARE_LINK_PREFIX_DEV}${paymentData?.id}`
                                : `${process.env.REACT_APP_SHARE_LINK_PREFIX_PROD}${paymentData?.id}`,
                          }}
                        >
                          Copy
                        </Text>
                      </span>

                      {paymentData?.required_info !== undefined ? (
                        <span>
                          {paymentData?.required_info.indexOf('user_name') >
                          -1 ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Your Name</Text>
                              <Form.Item
                                name="user_name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your name.',
                                  },
                                ]}
                                style={{ marginBottom: 0, width: '20rem' }}
                              >
                                <Input
                                  disabled={
                                    paymentData?.type !== 'recurring' &&
                                    (paymentData?.date_paid || isPaid)
                                  }
                                  placeholder={
                                    paymentData.user_name
                                      ? paymentData.user_name
                                      : 'Your Name'
                                  }
                                />
                              </Form.Item>
                            </p>
                          ) : null}
                          {paymentData?.required_info.indexOf('user_email') >
                          -1 ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Your Email</Text>
                              <Form.Item
                                name="user_email"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your email.',
                                  },
                                ]}
                                style={{ marginBottom: 0, width: '20rem' }}
                              >
                                <Input
                                  disabled={
                                    paymentData?.type !== 'recurring' &&
                                    (paymentData?.date_paid || isPaid)
                                  }
                                  placeholder={
                                    paymentData.user_name
                                      ? paymentData.user_email
                                      : 'Your Email'
                                  }
                                  type="email"
                                />
                              </Form.Item>
                            </p>
                          ) : null}
                          {paymentData?.required_info.indexOf(
                            'shipping_address',
                          ) > -1 ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Your Shipping Address</Text>
                              <Form.Item
                                name="shipping_address"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Please input your shipping address.',
                                  },
                                ]}
                                style={{ marginBottom: 0, width: '20rem' }}
                              >
                                <TextArea
                                  disabled={
                                    paymentData?.type !== 'recurring' &&
                                    (paymentData?.date_paid || isPaid)
                                  }
                                  rows={2}
                                  placeholder={
                                    paymentData.shipping_address
                                      ? paymentData.shipping_address
                                      : 'Your Address'
                                  }
                                />
                              </Form.Item>
                            </p>
                          ) : null}
                          {paymentData?.required_info.indexOf('user_message') >
                          -1 ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Message to Merchant</Text>
                              <Form.Item
                                name="user_message"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your message.',
                                  },
                                ]}
                                style={{ marginBottom: 0, width: '20rem' }}
                              >
                                <TextArea
                                  disabled={
                                    paymentData?.type !== 'recurring' &&
                                    (paymentData?.date_paid || isPaid)
                                  }
                                  rows={2}
                                  placeholder={
                                    paymentData.user_message
                                      ? paymentData.user_message
                                      : 'Leave any message for merchant.'
                                  }
                                />
                              </Form.Item>
                            </p>
                          ) : null}
                        </span>
                      ) : null}
                      {paymentData?.type === 'recurring' ||
                      paymentData?.type === 'fund_raising' ? (
                        <span>
                          {paymentData?.inventory ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Available Stock</Text>
                              <Text code>{`${
                                paymentData?.inventory -
                                paymentData?.recurring_payments?.length
                              }/${
                                paymentData?.inventory
                                  ? paymentData?.inventory
                                  : 1
                              }`}</Text>
                            </p>
                          ) : null}
                        </span>
                      ) : (
                        <span>
                          {paymentData?.date_paid ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Paid On</Text>
                              <Text code>{paymentData?.date_paid}</Text>
                            </p>
                          ) : null}
                          {paymentData?.txid ? (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Text>Success Txid</Text>
                              <Text code>
                                <a
                                  href={
                                    'https://explorer.solana.com/tx/' +
                                    paymentData?.txid
                                  }
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {paymentData?.txid}
                                </a>
                              </Text>
                            </p>
                          ) : null}
                        </span>
                      )}
                    </Panel>
                  </Collapse>
                  {paymentData?.type === 'recurring' ||
                  paymentData?.type === 'fund_raising' ? (
                    <span>
                      {paymentData?.inventory ? (
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text>Available Stock</Text>
                          <Text code>{`${
                            paymentData?.inventory -
                            paymentData?.recurring_payments?.length
                          }/${
                            paymentData?.inventory ? paymentData?.inventory : 1
                          }`}</Text>
                        </p>
                      ) : null}

                      {!walletKey ? (
                        <Alert
                          style={{ marginTop: '15px' }}
                          message="Connect your Solana wallet to make purchase and see your past orders for this item."
                          type="info"
                        />
                      ) : (
                        <span>
                          {paymentData?.recurring_payments ? (
                            <List
                              header={<div> Order History</div>}
                              bordered
                              dataSource={paymentData?.recurring_payments}
                              renderItem={(item: any) => (
                                <List.Item>
                                  <Typography>
                                    <span>
                                      Txid:{' '}
                                      <Text code>
                                        <a
                                          href={
                                            'https://explorer.solana.com/tx/' +
                                            item.txid
                                          }
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {item?.txid}
                                        </a>
                                      </Text>
                                    </span>
                                    <br />
                                    <span>
                                      Paid On:{' '}
                                      <Text code>{item.date_paid}</Text>
                                    </span>
                                    <br />
                                    <span>
                                      Coin / Quantity:{' '}
                                      <Text code>
                                        {item.token_symbol} / {item.quantity}
                                      </Text>
                                    </span>
                                    <br />
                                    {item.user_email ? (
                                      <span>
                                        User Email:{' '}
                                        <Text code>{item.user_email}</Text>
                                        <br />
                                      </span>
                                    ) : null}
                                    {item.user_name ? (
                                      <span>
                                        User Name:{' '}
                                        <Text code>{item.user_name}</Text>
                                        <br />
                                      </span>
                                    ) : null}
                                    {item.user_message ? (
                                      <span>
                                        User Message:{' '}
                                        <Text code>{item.user_message}</Text>
                                      </span>
                                    ) : null}
                                    {item.shipping_address ? (
                                      <span>
                                        Shipping Address:{' '}
                                        <Text code>
                                          {item.shipping_address}
                                        </Text>
                                      </span>
                                    ) : null}
                                  </Typography>
                                </List.Item>
                              )}
                              style={{ marginTop: '30px' }}
                            />
                          ) : (
                            <p style={{ marginTop: '30px' }}>
                              You have no past order history for the item.
                            </p>
                          )}
                        </span>
                      )}
                    </span>
                  ) : null}

                  <Paragraph style={{ marginTop: '15px' }}>
                    <Text type="secondary">
                      <small>
                        <a
                          href="https://docs.getbalancesoft.com/user/how-to-make-payments"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Guide
                        </a>{' '}
                        to making payments on Balancesoft.
                      </small>
                    </Text>
                  </Paragraph>
                </Card>
              </Form>
            </>
          ) : (
            <>
              <Skeleton />
            </>
          )}
        </FloatingElement>
      </Wrapper>
    </>
  );
}
