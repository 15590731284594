import {
  Avatar,
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { POPULAR_FIAT, POPULAR_TOKENS } from '../utils/tokenTypes';
import React, { useEffect, useState } from 'react';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import apiClient from '../utils/apiClient';
import firebaseHandler from '../config/firebase.js';
import { notify } from '../utils/notifications';
import styled from 'styled-components';

const { Option, OptGroup } = Select;
const { Text, Title } = Typography;
const { Search } = Input;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

export default function IsLoaded() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const unregisterAuthObserver = firebaseHandler
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver();
  }, []);

  return <>{isSignedIn ? <TrackPaymentsPage /> : null}</>;
}

function TrackPaymentsPage() {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) => (
        <Text code>
          <Link to={`/pay/${record.id}`}>{text}</Link>{' '}
        </Text>
      ),
    },
    {
      title: 'Date Paid',
      dataIndex: 'date_paid',
      key: 'date_paid',
      sorter: (a, b) => a.date_paid.localeCompare(b.date_paid),
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Item Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Quantity',
      dataIndex: 'price_quantity',
      key: 'price_quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Price Token',
      dataIndex: 'price_token',
      key: 'price_token',
      sorter: (a, b) => a.price_token.localeCompare(b.price_token),
      ellipsis: true,
      render: (text) => <Text code>{text}</Text>,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Recurring',
          value: 'recurring',
        },
        {
          text: 'One Time',
          value: 'one_time',
        },
        {
          text: 'Fund Raising',
          value: 'fund_raising',
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (text, record) => (
        <span>
          <Tag>
            {text === 'one_time' ? 'One Time' : null}
            {text === 'recurring' ? 'Recurring' : null}
            {text === 'fund_raising' ? 'Fund Raising' : null}
          </Tag>
        </span>
      ),
    },
    {
      title: `Merchant's Wallet`,
      key: 'solana_wallet_address',
      dataIndex: 'solana_wallet_address',
      sorter: (a, b) => a.solana_wallet_address.localeCompare(b.public_key),
      render: (text) =>
        text ? (
          <Text code>
            {
              <a
                href={'https://explorer.solana.com/tx/' + text}
                rel="noopener noreferrer"
                target="_blank"
              >
                {text || '-'}
              </a>
            }
          </Text>
        ) : (
          <Text code>-</Text>
        ),
    },
  ];

  const [accountData, setAccountData] = useState<any>(null);
  const [isLoadedAccount, setIsLoadedAccount] = useState(false);
  const [dataList, setDataList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchFilterform] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .get(`/account_settings`, {
              headers: { firebase_token: idToken },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setAccountData(responseObject?.data?.data);
                setIsLoadedAccount(true);
              }
            });
        });
    };
    fetchData();
    loadPaymentRequests();
    // eslint-disable-next-line
  }, []);

  const loadPaymentRequests = async () => {
    try {
      setDataList([]);
      setIsLoading(true);
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/track_payment/filter`,
              {},
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setIsLoading(false);
                setDataList(responseObject?.data?.data);
                message.success('Data Loaded');
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onSubmitFilterModal = (values: any) => {
    try {
      firebaseHandler!
        .auth()!
        .currentUser!.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(`/payment_request/filter`, values, {
              headers: { firebase_token: idToken },
              params: values,
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setDataList(responseObject?.data?.data);
                setIsSearchFilterModalVisible(false);
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onIdSearch = (value) => {
    if (value === '') {
      return notify({
        message: 'Please provide request id',
        type: 'error',
      });
    }
    try {
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .get(`/payment_request/filter`, {
              headers: { firebase_token: idToken },
              params: { id: value },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setIsLoading(false);
                setDataList(responseObject?.data?.data);
                setIsSearchModalVisible(false);
                message.success('Data Loaded');
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
  };

  // Search forms
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isSearchFilterModalVisible, setIsSearchFilterModalVisible] = useState(
    false,
  );

  const showSearchModal = () => {
    setIsSearchModalVisible(true);
  };

  const showSearchFilterModal = () => {
    setIsSearchFilterModalVisible(true);
  };

  const handleSearchCancel = () => {
    setIsSearchModalVisible(false);
  };

  const handleSearchFilterCancel = () => {
    setIsSearchFilterModalVisible(false);
  };

  return (
    <Wrapper>
      <Modal
        title="Search By Payment Request ID"
        visible={isSearchModalVisible}
        footer={null}
        onCancel={handleSearchCancel}
      >
        <Search
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          allowClear
          enterButton="Search"
          onSearch={onIdSearch}
        />
      </Modal>
      <Modal
        title="Search With Filter"
        visible={isSearchFilterModalVisible}
        footer={null}
        onCancel={handleSearchFilterCancel}
      >
        <Form
          {...formItemLayout}
          form={searchFilterform}
          requiredMark={false}
          onFinish={onSubmitFilterModal}
        >
          <Form.Item name="type" label="Payment Type">
            <Select defaultValue="all">
              <Option value="all">All</Option>
              <Option value="one_time">One-Time</Option>
              <Option value="recurring">Recurring</Option>
              <Option value="fund_raising">
                Multiple Payments (User to Input Price, Fund Raising)
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="price_token" label="Price Token">
            <Select defaultValue="all" allowClear>
              <Option key="all" value="all">
                All
              </Option>
              <OptGroup label="Crypto">
                {POPULAR_TOKENS.map((i) => (
                  <React.Fragment key={i.mintAddress}>
                    {
                      <Option key={i.mintAddress} value={i.tokenSymbol}>
                        <Avatar
                          src={
                            <Image
                              preview={false}
                              style={{ width: '1rem' }}
                              src={i.icon}
                            />
                          }
                        />
                        &nbsp;
                        {i.tokenSymbol}
                      </Option>
                    }
                  </React.Fragment>
                ))}
              </OptGroup>
              <OptGroup label="Fiat">
                {POPULAR_FIAT.map((i) => (
                  <React.Fragment key={i.mintAddress}>
                    {
                      <Option key={i.code} value={i.code}>
                        <Avatar
                          src={
                            <Image
                              preview={false}
                              style={{ width: '1rem' }}
                              src={i.icon}
                            />
                          }
                        />
                        &nbsp;
                        {i.code}
                      </Option>
                    }
                  </React.Fragment>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="created_source" label="Created Source">
            <Select defaultValue="all">
              <Option value="all">All</Option>
              <Option value="web">Web</Option>
              <Option value="api">API</Option>
            </Select>
          </Form.Item>
          <Form.Item name="paid_status" label="Paid Status">
            <Select defaultValue="all">
              <Option value="all">All</Option>
              <Option value="paid">Paid</Option>
              <Option value="unpaid">Unpaid</Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  searchFilterform.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Title level={3}>Track Payments Made</Title>
      {!accountData?.solana_wallet_address && isLoadedAccount ? (
        <Text type="warning">
          <Link to={`/account-settings`}>
            <Text underline type="warning">
              Sync
            </Text>
          </Link>{' '}
          your Solana wallet address to Balancesoft under account settings
          before creating your first payment request.
        </Text>
      ) : null}
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Text strong>Payments Data</Text>
          <Button icon={<SearchOutlined />} onClick={showSearchModal}>
            Id
          </Button>
          <Button icon={<SearchOutlined />} onClick={showSearchFilterModal}>
            Filter
          </Button>
        </Space>
        <Space style={{ marginBottom: 15, marginTop: 15 }}>
          <Button
            disabled={accountData?.solana_wallet_address == null}
            icon={<SyncOutlined />}
            onClick={() => {
              loadPaymentRequests();
            }}
          >
            Refresh
          </Button>
        </Space>
      </span>
      <Table
        columns={columns}
        dataSource={dataList}
        scroll={{ x: 1500 }}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              {record.date_created ? (
                <span>
                  Date Created: <Text code>{record.date_created || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.date_due ? (
                <span>
                  Date Due: <Text code>{record.date_due}</Text>
                  <br />
                </span>
              ) : null}
              {record.date_paid && !record.recurring_payments ? (
                <span>
                  Date Paid: <Text code>{record.date_paid}</Text>
                  <br />
                </span>
              ) : null}
              {record.id ? (
                <span>
                  Invoice Id: <Text code>{record.id || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.memo ? (
                <span>
                  Memo: <Text code>{record.memo || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.name ? (
                <span>
                  Name: <Text code>{record.name || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.notification_email ? (
                <span>
                  Notification Email:{' '}
                  <Text code>{record.notification_email || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.public_key ? (
                <span>
                  Public Key: <Text code>{record.public_key || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.quantity ? (
                <span>
                  Quantity: <Text code>{record.quantity || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.required_info ? (
                <span>
                  Required Info:{' '}
                  <Text code>
                    {JSON.stringify(record.required_info) || '-'}
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.triggers ? (
                <span>
                  Triggers:{' '}
                  <Text code>{JSON.stringify(record.triggers) || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.status ? (
                <span>
                  Status: <Text code>{record.status || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.title ? (
                <span>
                  Title: <Text code>{record.title || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.token_symbol ? (
                <span>
                  Token Symbol: <Text code>{record.token_symbol || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.coin ? (
                <span>
                  Coin: <Text code>{record.coin || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.id ? (
                <span>
                  Id: <Text code>{record.id || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.txid ? (
                <span>
                  Paid Txid:{' '}
                  <Text code>
                    <a
                      href={'https://explorer.solana.com/tx/' + record.txid}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {record.txid || '-'}
                    </a>
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.type ? (
                <span>
                  Type:{' '}
                  <Text code style={{ textTransform: 'capitalize' }}>
                    {record.type.replace('_', ' ') || '-'}
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.image_url ? (
                <span style={{ display: 'flex' }}>
                  Image:&nbsp;
                  {record.image_url ? (
                    <Image width={100} src={record.image_url} />
                  ) : (
                    '-'
                  )}
                </span>
              ) : null}

              {record.recurring_payments ? (
                <span>
                  User Payments:
                  <br />
                  {record.recurring_payments.map((item, key) => (
                    <span key={key}>
                      <span>
                        {' '}
                        <Text underline>({key})Txid: </Text>
                        <Text code>
                          {' '}
                          <a
                            href={'https://explorer.solana.com/tx/' + item.txid}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.txid}
                          </a>
                        </Text>
                        <br />
                      </span>
                      {item.user_name ? (
                        <span>
                          User Name: <Text code>{item.user_name}</Text>
                        </span>
                      ) : null}
                      {item.date_paid ? (
                        <span>
                          Date Paid: <Text code>{item.date_paid}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.shipping_address ? (
                        <span>
                          Shipping Address:{' '}
                          <Text code>{item.shipping_address}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.payer_wallet ? (
                        <span>
                          Payer Wallet: <Text code>{item.payer_wallet}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.user_email ? (
                        <span>
                          User Email: <Text code>{item.user_email}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.user_message ? (
                        <span>
                          User Message: <Text code>{item.user_message}</Text>
                          <br />
                        </span>
                      ) : null}
                    </span>
                  ))}
                </span>
              ) : null}
            </p>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        loading={isLoading}
        rowKey="id"
      />
    </Wrapper>
  );
}
