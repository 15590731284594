import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AccountSettings from './pages/AccountSettings';
import BasicLayout from './components/BasicLayout';
import CreatePaymentRequestPage from './pages/CreatePaymentRequest';
import Developer from './pages/Developer';
import HomePage from './pages/HomePage';
import MakeInstantPaymentPage from './pages/MakeInstantPayment';
import OpenOrdersPage from './pages/OpenOrdersPage';
import React from 'react';
import SmartContracts from './pages/SmartContracts';
import TrackPaymentsMade from './pages/TrackPaymentsMade';

export function Routes() {
  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <BasicLayout>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/account-settings">
              <AccountSettings />
            </Route>
            <Route exact path="/developer">
              <Developer />
            </Route>
            <Route
              exact
              path="/create-payment"
              component={CreatePaymentRequestPage}
            />
            <Route exact path="/smart-contracts" component={SmartContracts} />
            <Route
              exact
              path="/track-payments-made"
              component={TrackPaymentsMade}
            />
            <Route exact path="/pay/:id?" component={MakeInstantPaymentPage} />
            <Route exact path="/orders" component={OpenOrdersPage} />
          </Switch>
        </BasicLayout>
      </BrowserRouter>
    </div>
  );
}
