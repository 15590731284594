export const POPULAR_TOKENS = [
  {
    tokenSymbol: 'USDC',
    yfSymbol: 'USDC-USD',
    mintAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    decimal: 6,
    tokenName: 'USD Coin',
    referral: 'EC62FoJFfvjYYinCxS7NLKyd9fSFQ7dQoWj29RhEA51e',
    icon:
      'https://raw.githubusercontent.com/trustwallet/assets/f3ffd0b9ae2165336279ce2f8db1981a55ce30f8/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  },
  {
    tokenSymbol: 'USDT',
    yfSymbol: 'USDT-USD',
    mintAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    decimal: 6,
    tokenName: 'USDT',
    referral: '55LMgDETXgW2cyPoEF2weCuviceL14f3NtfR6r53kbgu',
    icon:
      'https://cdn.jsdelivr.net/gh/solana-labs/explorer/public/tokens/usdt.svg',
  },
  {
    tokenSymbol: 'BTC',
    yfSymbol: 'BTC-USD',
    mintAddress: '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E',
    decimal: 6,
    tokenName: 'Wrapped Bitcoin',
    referral: 'AN416QfwtvSdMSpLD9jRuokRq2SH57vRH3dw1bPkdpd4',
    icon:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/bitcoin/info/logo.png',
  },
  {
    tokenSymbol: 'ETH',
    yfSymbol: 'ETH-USD',
    mintAddress: '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk',
    decimal: 6,
    tokenName: 'Wrapped Ethereum',
    referral: '76ADA4SocFtE3S9zpgbtzmQZrnCSmaP9anKnTUvC5CQx',
    icon:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  {
    tokenSymbol: 'SOL',
    yfSymbol: 'SOL1-USD',
    mintAddress: '0',
    tokenName: 'Solana',
    decimal: 9,
    icon: 'https://cryptologos.cc/logos/solana-sol-logo.png',
  },
];

export const POPULAR_FIAT = [
  {
    code: 'USD',
    yfSymbol: 'USD',
    symbol: 'US$',
    name: 'United States dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/35px-Flag_of_the_United_States.svg.png',
  },

  {
    code: 'CNY',
    yfSymbol: 'CNY=X',
    symbol: '元',
    name: 'Renminbi',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/35px-Flag_of_the_People%27s_Republic_of_China.svg.png',
  },
  {
    code: 'KRW',
    yfSymbol: 'KRW=X',
    symbol: '₩',
    name: 'South Korean won',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/35px-Flag_of_South_Korea.svg.png',
  },
  {
    code: 'SGD',
    yfSymbol: 'SGD=X',
    symbol: 'S$',
    name: 'Singapore dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/35px-Flag_of_Singapore.svg.png',
  },
  {
    code: 'MXN',
    yfSymbol: 'MXN=X',
    symbol: '$',
    name: 'Mexican peso',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/35px-Flag_of_Mexico.svg.png',
  },
  {
    code: 'INR',
    yfSymbol: 'INR=X',
    symbol: '₹',
    name: 'Indian rupee',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/35px-Flag_of_India.svg.png',
  },
  {
    code: 'RUB',
    yfSymbol: 'RUB=X',
    symbol: '₽',
    name: 'Russian ruble',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Flag_of_Russia.svg/35px-Flag_of_Russia.svg.png',
  },
  {
    code: 'ZAR',
    yfSymbol: 'ZAR=X',
    symbol: 'R',
    name: 'South African rand',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/35px-Flag_of_South_Africa.svg.png',
  },
  {
    code: 'TRY',
    yfSymbol: 'TRY=X',
    symbol: '₺',
    name: 'Turkish lira',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/35px-Flag_of_Turkey.svg.png',
  },
  {
    code: 'BRL',
    yfSymbol: 'BRL=X',
    symbol: 'R$',
    name: 'Brazilian real',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/33px-Flag_of_Brazil.svg.png',
  },
  {
    code: 'TWD',
    yfSymbol: 'TWD=X',
    symbol: 'NT$',
    name: 'New Taiwan dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Flag_of_the_Republic_of_China.svg/35px-Flag_of_the_Republic_of_China.svg.png',
  },
  {
    code: 'THB',
    yfSymbol: 'THB=X',
    symbol: '฿',
    name: 'Thai baht',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/35px-Flag_of_Thailand.svg.png',
  },

  {
    code: 'PHP',
    yfSymbol: 'PHP=X',
    symbol: '₱',
    name: 'Philippine peso',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/35px-Flag_of_the_Philippines.svg.png',
  },
  {
    code: 'MYR',
    yfSymbol: 'MYR=X',
    symbol: 'RM',
    name: 'Malaysian ringgit',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_Malaysia.svg/35px-Flag_of_Malaysia.svg.png',
  },
  {
    code: 'EUR',
    yfSymbol: 'EUR=X',
    symbol: '€',
    name: 'Euro',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/35px-Flag_of_Europe.svg.png',
  },
  {
    code: 'JPY',
    yfSymbol: 'JPY=X',
    symbol: '¥',
    name: 'Japanese yen',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/35px-Flag_of_Japan.svg.png',
  },
  {
    code: 'GBP',
    yfSymbol: 'GBP=X',
    symbol: '£',
    name: 'Pound sterling',
    icon:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/35px-Flag_of_the_United_Kingdom.svg.png',
  },
  {
    code: 'AUD',
    yfSymbol: 'AUD=X',
    symbol: 'A$',
    name: 'Australian dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/35px-Flag_of_Australia_%28converted%29.svg.png',
  },
  {
    code: 'CAD',
    yfSymbol: 'CAD=X',
    symbol: 'C$',
    name: 'Canadian dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/35px-Flag_of_Canada_%28Pantone%29.svg.png',
  },
  {
    code: 'CHF',
    yfSymbol: 'CHF=X',
    symbol: 'CHF',
    name: 'Swiss franc',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/24px-Flag_of_Switzerland.svg.png',
  },
  {
    code: 'NZD',
    yfSymbol: 'NZD=X',
    symbol: 'NZ$',
    name: 'New Zealand dollar',
    icon:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/35px-Flag_of_New_Zealand.svg.png',
  },
];
