import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  List,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';

import apiClient from '../utils/apiClient';
import firebaseHandler from '../config/firebase.js';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const db = firebaseHandler.firestore();
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 50px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

export default function DeveloperPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 600px)',
  });

  const [form] = Form.useForm();
  const [formShopify] = Form.useForm();
  const [apiKeys, setApiKeys] = useState(null);
  const [addOnBefore, setAddOnBefore] = useState('http://');

  useEffect(() => {
    fetchDataExternal();
    fetchApiKeys();
    fetchShopifySettings();
    setTimeout(function () {
      fetchDataExternal();
      fetchShopifySettings();
      fetchApiKeys();
    }, 3000);
    // eslint-disable-next-line
  }, []);

  const fetchApiKeys = () => {
    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .get(`/api_keys`, {
              headers: { firebase_token: idToken },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setApiKeys(responseObject?.data?.data);
              }
            });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const createApiKey = () => {
    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/api_keys`,
              {},
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                fetchApiKeys();
              }
            });
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteApiKey = (item) => {
    const api_key = item.api_key;
    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .delete(`/api_keys/${api_key}`, {
              headers: { firebase_token: idToken },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                fetchApiKeys();
              }
            });
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchDataExternal = () => {
    firebaseHandler.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var docRef = db.collection('developer_settings').doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const docData = doc.data();
              form.setFieldsValue({
                enable_webhook: docData?.enable_webhook,
                end_point: docData?.end_point.split('://')[1],
              });
            } else {
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error);
          });
      }
    });
  };

  const fetchShopifySettings = () => {
    firebaseHandler.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var docRef = db.collection('shopify_developer_settings').doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const docData = doc.data();
              formShopify.setFieldsValue({
                shopify_api_key: docData?.shopify_api_key,
                shopify_password: docData?.shopify_password,
                shopify_url: docData?.shopify_url,
              });
            } else {
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error);
          });
      }
    });
  };

  const onFinish = (values) => {
    const end_point = addOnBefore + values.end_point;
    const expression = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    const expressionSchema = new RegExp('^(http|https)://', 'i');
    if (!end_point.match(regex) || !end_point.match(expressionSchema)) {
      message.error(`Check if url format is valid.`);
      return;
    }

    values.end_point = end_point;
    const user = firebaseHandler.auth().currentUser;
    const uid = user.uid;
    const docRef = db.collection('developer_settings').doc(uid);

    docRef
      .set(values, { merge: true })
      .then(() => {
        fetchDataExternal();
        message.success('Update success.');
      })
      .catch((error) => {
        message.error(`Update error - ${error}.`);
      });
  };

  const connectShopifyStore = (values) => {
    const user = firebaseHandler.auth().currentUser;
    const uid = user.uid;
    const docRef = db.collection('shopify_developer_settings').doc(uid);
    docRef
      .set(values, { merge: true })
      .then(() => {
        fetchDataExternal();
        message.success('Update success.');
      })
      .catch((error) => {
        message.error(`Update error - ${error}.`);
      });
  };

  return (
    <Wrapper>
      <Row
        style={{
          marginTop: '5rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={!isDesktopOrLaptop ? 18 : 16}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="API Management" key="1">
              <Paragraph>
                <Text>
                  Start by reading the{' '}
                  <a
                    href="https://docs.getbalancesoft.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Developer's API Guide
                  </a>
                </Text>
              </Paragraph>
              <List
                bordered
                dataSource={apiKeys || []}
                renderItem={(item) => (
                  <List.Item>
                    <Typography>
                      <Text strong>API Key: </Text>
                      <Text copyable>{item.api_key}</Text>
                      <br />
                      <Text strong>Secret Key: </Text>
                      <Text copyable>{item.secret_key}</Text>
                    </Typography>
                    <Popconfirm
                      title="Are you sure to delete this API Key?"
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" onClick={() => deleteApiKey(item)}>
                        Delete
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
              <Space>
                <Button
                  style={{ marginTop: '15px' }}
                  type="primary"
                  onClick={() => createApiKey()}
                >
                  Create New API Key
                </Button>
              </Space>
            </Panel>
            <Panel header="Webhook End Point" key="2">
              <Form
                form={form}
                labelCol={{ span: 6 }}
                onFinish={onFinish}
                requiredMark={false}
                style={{ marginTop: '15px' }}
              >
                <p>URL:</p>
                <Form.Item
                  name="end_point"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the url for your endpoint.',
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Select
                        defaultValue="http://"
                        className="select-before"
                        onChange={(e) => setAddOnBefore(e)}
                      >
                        <Option value="http://">http://</Option>
                        <Option value="https://">https://</Option>
                      </Select>
                    }
                    placeholder="Your end point url"
                  />
                </Form.Item>
                <Form.Item name="enable_webhook">
                  <Checkbox.Group>
                    <Checkbox value="enable_webhook">Enable Webhook</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
                <p>Your end point starts with https:// or http://</p>
              </Form>
            </Panel>
            <Panel header="Shopify Settings" key="3">
              <Form form={formShopify} onFinish={connectShopifyStore}>
                <Form.Item
                  name="shopify_url"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide the Shopify url',
                    },
                  ]}
                >
                  <Input addonBefore="https://" addonAfter=".myshopify.com" />
                </Form.Item>
                <Form.Item label="Shopify API Key" name="shopify_api_key">
                  <Input />
                </Form.Item>
                <Form.Item label="Shopify Password" name="shopify_password">
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Wrapper>
  );
}
