import './App.less';

import React, { Suspense } from 'react';
import { combineReducers, createStore } from 'redux';

import { ConnectionProvider } from './utils/connection';
import ErrorBoundary from './components/ErrorBoundary';
import { GlobalStyle } from './global_style';
import { PreferencesProvider } from './utils/preferences';
import { Provider } from 'react-redux';
import { ReferrerProvider } from './utils/referrer';
import { Routes } from './routes';
import { Spin } from 'antd';
import { WalletProvider } from './utils/wallet';

const isLive = (state = true, action) => {
  switch (action.type) {
    case 'TOGGLE_IS_LIVE':
      return action.payload;
    default:
      return state;
  }
};
const rootReducer = combineReducers({
  isLive,
});
const store = createStore(rootReducer);

export default function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={() => <Spin size="large" />}>
        <GlobalStyle />
        <ErrorBoundary>
          <ConnectionProvider>
            <ReferrerProvider>
              <WalletProvider>
                <PreferencesProvider>
                  <Suspense fallback={() => <Spin size="large" />}>
                    <Routes />
                  </Suspense>
                </PreferencesProvider>
              </WalletProvider>
            </ReferrerProvider>
          </ConnectionProvider>
        </ErrorBoundary>
      </Suspense>
    </Provider>
  );
}
