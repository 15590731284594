import {
  Button,
  Card,
  Col,
  Collapse,
  Image,
  Row,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from 'antd';
import { CodeBlock, dracula } from 'react-code-blocks';
import React, { useState } from 'react';

import Link from '../components/Link';
import cryptoLogo from '../assets/crypto_payments_accepted.png';
import logo from '../assets/powered_by_balancesoft_square.png';
import rocketshipLogo from '../assets/logo_rocketship_transparent.png';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const { Meta } = Card;
const { Paragraph, Text, Title } = Typography;
const { Panel } = Collapse;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 50px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

export default function HomePage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 600px)',
  });

  const [isDemoPaymentClicked, setIsDemoPaymentClicked] = useState(false);

  return (
    <Wrapper>
      <Row style={{ marginTop: '8rem' }}>
        <Col offset={2} span={12}>
          <Title>Plug & Play Stablecoin Payments for Small & Medium Businesses</Title>
          <Paragraph>
            Stablecoin platform for SMBs to easily receive payments via invoicing and checkouts. Supporting EVM and Solana chains. <b>Balance</b>sheet <b>Soft</b>ware.
          </Paragraph>
          <Space>
            <Link to={'/create-payment'}>
              <Button type="primary">Start Now</Button>
            </Link>
            {/* No customer service until I get resources */}
            {/* <Button
              type="button"
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  'https://telegram.me/getbalancesoft',
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              Telegram Customer Service
            </Button> */}
          </Space>
        </Col>
        <Col
          offset={2}
          span={6}
          style={{ marginTop: '1rem', textAlign: 'right' }}
        >
          <Space direction="vertical">
            <Statistic title="Settlement Time" value={'Few secs'} />
            <Text type="secondary">
              <small>instantaneous transactions</small>
            </Text>
            <Statistic title="Transaction Fee" value={'Low'} />
            <Text type="secondary">
              <small>vs. 2.5-3% of payment processors</small>
            </Text>
          </Space>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={!isDesktopOrLaptop ? 18 : 16}>
          <Title level={3}>Use Case</Title>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="No-Code Method" key="1">
              <Title level={5}>1 Create Payment Link</Title>
              <Paragraph>
                Sell a product or service across the internet. You can collect
                one-time for recurring payments for your items. You decide what
                coin to receive (stablecoin or not).
              </Paragraph>
              <Title level={5}>2a Either Share Link</Title>
              <Paragraph>
                Send and share the link anywhere. Each payment link is unique to
                a payment request.
              </Paragraph>
              <Title level={5} style={{ marginBottom: '15px' }}>
                2b Or Copy HTML snippet for Balancesoft Pay Button
              </Title>
              <Paragraph style={{ marginTop: '15px' }}>
                Embed the generated Balancesoft Pay button HTML to any website.
                Each button is unique to each payment request.
              </Paragraph>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '5px',
                }}
              >
                Preview of embedded button:{' '}
              </p>
              <Tooltip title="This is only a dummy button. The actual one will direct to a hosted Balancesoft Pay Page.">
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                  dangerouslySetInnerHTML={{
                    __html: `<button style="background: #fd9251; border-color: #fd9251; border-radius: 2px; border: 1px solid transparent; color: white; cursor: select; outline: 0; padding: 4px 15px;">Balancesoft Pay</button>`,
                  }}
                />
              </Tooltip>
              <Title level={5} style={{ marginTop: '20px' }}>
                3 Get Paid in Coins via the Internet
              </Title>
              <Paragraph>
                Both merchant and user will be notified via email when payment
                is made. Coins will go straight to your Solana Wallet.
              </Paragraph>
            </Panel>
            <Panel header="API Method" key="2">
              <Title level={5} style={{ marginTop: '15px' }}>
                Reconcile Payments to Your Backend
              </Title>
              <Paragraph>
                A post request with body info will be sent to your backend on
                successful payment.
              </Paragraph>
              {isDemoPaymentClicked ? (
                <p>Example of post request sent.</p>
              ) : null}
              <CodeBlock
                text={
                  !isDemoPaymentClicked
                    ? 'Pending...'
                    : JSON.stringify(
                        {
                          data: {
                            payload: {
                              type: 'one_time',
                              title: 'Demo Title',
                              token_symbol: 'USDC',
                              price_quantity: 1000,
                              picture_url:
                                'https://i.insider.com/5e67b51bf0b61066ae625c16?width=700',
                              memo: 'Test Memo',
                              inventory: null,
                              required_info: {
                                shipping_address: true,
                                user_name: true,
                                user_email: true,
                                user_message: true,
                              },
                              redirect_link: 'getbalancesoft.com',
                              id: '4fc088bd-4cee-46ee-a45a-ae04aa920880',
                              date_created: '2021-10-19 05:11:08',
                              user_id: '7fVKVAsjz6NQm8l7LbHPA4AYrt43',
                              link:
                                'getbalancesoft.com/pay/4fc088bd-4cee-46ee-a45a-ae08a920780',
                            },
                          },
                          message: null,
                          status: 'success',
                        },
                        null,
                        2,
                      )
                }
                language={'jsx'}
                showLineNumbers={true}
                theme={dracula}
                wrapLines
              />
              <Space>
                <Button
                  style={{ marginTop: '15px' }}
                  type="primary"
                  onClick={() => setIsDemoPaymentClicked(!isDemoPaymentClicked)}
                >
                  {!isDemoPaymentClicked ? 'Make Demo Payment' : 'Hide'}
                </Button>
                <Button
                  style={{ marginTop: '15px' }}
                  onClick={() =>
                    window.open(
                      'https://app.swaggerhub.com/apis-docs/Balancesoft/Balancesoft_Client_API',
                      '_blank',
                    )
                  }
                >
                  Explore Swagger API Doc
                </Button>
              </Space>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={!isDesktopOrLaptop ? 14 : 12}>
          <Title level={3}>Platforms Accepting Balancesoft Pay</Title>
          <Row>
            <div style={{ width: 'holder', display: 'inline-block' }}>
              <Image
                src={rocketshipLogo}
                alt=""
                preview={false}
                style={{ width: '10rem', height: 'auto', cursor: 'pointer' }}
                onClick={() =>
                  window.open('https://rocketshipsoftware.co', '_blank')
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        gutter={16}
      >
        <Title level={3}>Guides</Title>
      </Row>
      <Row
        style={{
          marginTop: '3rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        gutter={16}
      >
        <Col span={!isDesktopOrLaptop ? 10 : 8}>
          <Card
            hoverable
            onClick={() =>
              window.open('https://docs.getbalancesoft.com', '_blank')
            }
            cover={
              <img
                alt="example"
                src="https://images.unsplash.com/photo-1533821312764-eb0483f98f69?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
              />
            }
          >
            <Meta
              title="User How-To Guide"
              description="General guide to using Balancesoft payments"
            />
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '3rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        gutter={16}
      >
        <Col span={!isDesktopOrLaptop ? 10 : 8}>
          <Card
            hoverable
            onClick={() =>
              window.open(
                'https://app.swaggerhub.com/apis-docs/Balancesoft/Balancesoft_Client_API',
                '_blank',
              )
            }
            cover={
              <img
                alt="example"
                src="https://images.unsplash.com/photo-1619410283995-43d9134e7656?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
              />
            }
          >
            <Meta
              title="Developer API Documentation"
              description="Swagger API documentation and ready testbed"
            />
          </Card>
        </Col>
        <Col span={!isDesktopOrLaptop ? 10 : 8}>
          <Card
            hoverable
            onClick={() =>
              window.open(
                'https://docs.getbalancesoft.com/merchant/how-to-create-payment-requests-generated-pay-button',
                '_blank',
              )
            }
            cover={
              <img
                alt="example"
                src="https://images.unsplash.com/photo-1471004984569-e97923fadd06?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
              />
            }
          >
            <Meta
              title="Guide: No-code Balancesoft Pay Button"
              description="For e-commerce merchants to accept crypto payments, for content creators to receive tips, and for organizations to receive donations and to do fund raising"
            />
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: '7rem',
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: 'holder', display: 'inline-block' }}>
          <Image
            src={logo}
            alt=""
            preview={false}
            style={{ width: '10rem', height: 'auto' }}
          />
        </div>
        <div style={{ width: 'holder', display: 'inline-block' }}>
          <Image
            src={cryptoLogo}
            alt=""
            preview={false}
            style={{ width: '10rem', height: 'auto' }}
          />
        </div>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Text type="secondary" style={{ textAlign: 'center' }}>
          Payment operations with cryptocurrency involves significant risks.
          Please consider whether using Balancesoft is appropriate for you.
        </Text>
      </Row>
    </Wrapper>
  );
}
