import firebase from 'firebase';

var firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? {
        apiKey: "AIzaSyBlwXs03GKIAn1ZvfRSfJ2WUtLsEgrTl14",
        authDomain: "balancesoft-dev-c7bfc.firebaseapp.com",
        projectId: "balancesoft-dev-c7bfc",
        storageBucket: "balancesoft-dev-c7bfc.firebasestorage.app",
        messagingSenderId: "612556865582",
        appId: "1:612556865582:web:5722870885ec89e19d5fbf",
        measurementId: "G-0734VBVTRG"
      }
    : {
        apiKey: "AIzaSyDxIdd0iq0E5PdZgNXeAhpZqbYu733x-0A",
        authDomain: "balancesoft-974e4.firebaseapp.com",
        projectId: "balancesoft-974e4",
        storageBucket: "balancesoft-974e4.firebasestorage.app",
        messagingSenderId: "269842370345",
        appId: "1:269842370345:web:9b318df335e7eed631f138",
        measurementId: "G-66H72MQHCW"
      };

firebase.initializeApp(firebaseConfig);
export default firebase;
