import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';
import apiClient from '../utils/apiClient';
import firebaseHandler from '../config/firebase.js';
import { notify } from '../utils/notifications';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useWallet } from '../utils/wallet';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 50px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

const { Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;

export default function ListNewMarketPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 600px)',
  });

  const [formWalletAddress] = Form.useForm();
  const [formCoinsToAccept] = Form.useForm();
  const [formUserSettings] = Form.useForm();
  const [formMerchantSettings] = Form.useForm();

  const [accountIsLoading, setAccountIsLoading] = useState(true);
  const [isVerifiedBusiness, setIsVerifiedBusiness] = useState(false);
  const { wallet, connected } = useWallet();
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [solanaWalletAddress, setSolanaWalletAddress] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      firebaseHandler.auth().onAuthStateChanged(function (user) {
        if (user) {
          setUserId(user?.uid);
          setUserEmail(user?.email);
          firebaseHandler
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              apiClient
                .get(`/account_settings`, {
                  headers: { firebase_token: idToken },
                })
                .then((responseObject) => {
                  if (responseObject.status === 200) {
                    formCoinsToAccept.setFieldsValue({
                      coins_to_accept:
                        responseObject?.data?.data?.coins_to_accept,
                    });

                    formWalletAddress.setFieldsValue({
                      solana_wallet_address:
                        responseObject?.data?.data?.solana_wallet_address,
                    });
                    setSolanaWalletAddress(
                      responseObject?.data?.data?.solana_wallet_address,
                    );

                    formUserSettings.setFieldsValue({
                      notification_email:
                        responseObject?.data?.data?.notification_email,
                    });

                    formMerchantSettings.setFieldsValue({
                      company_name: responseObject?.data?.data?.company_name,
                      goverment_id: responseObject?.data?.data?.goverment_id,
                      company_address:
                        responseObject?.data?.data?.company_address,
                      company_officer:
                        responseObject?.data?.data?.company_officer,
                      verified_business:
                        responseObject?.data?.data?.verified_business,
                    });
                    setIsVerifiedBusiness(
                      responseObject?.data?.data?.verified_business,
                    );

                    setAccountIsLoading(false);
                  }
                });
            });
        }
      });
    };
    fetchData();
  });

  function confirmUpdateSolanaWallAddress() {
    if (!wallet || !connected) {
      notify({
        message: 'Wallet not connected',
        description: 'Wallet not connected',
        type: 'error',
      });
      return;
    }

    const walletAddress = wallet?.publicKey?.toBase58();

    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/account_settings`,
              { solana_wallet_address: walletAddress },
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Update Account Settings',
                  description: `Success`,
                  type: 'success',
                });
                formWalletAddress.setFieldsValue({
                  solana_wallet_address: walletAddress,
                });
              }
            });
        });
    } catch (e) {
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  }

  const onSaveCoin = (values) => {
    const coins_to_accept = values.coins_to_accept;

    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/account_settings`,
              { coins_to_accept: coins_to_accept },
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Update Account Settings',
                  description: `Success`,
                  type: 'success',
                });
              }
            });
        });
    } catch (e) {
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onSaveWalletAddress = (values) => {
    const solana_wallet_address = values.solana_wallet_address;
    var regex = /[1-9A-HJ-NP-Za-km-z]{32,44}/;
    if (!regex.test(solana_wallet_address)) {
      return notify({
        message: 'Error',
        description: `Invalid Solana wallet address format, please check.`,
        type: 'error',
      });
    }

    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/account_settings`,
              { solana_wallet_address: solana_wallet_address },
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Update Account Settings',
                  description: `Success`,
                  type: 'success',
                });
              }
            });
        });
    } catch (e) {
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onSaveUserSettings = (values) => {
    const notification_email = values.notification_email;
    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/account_settings`,
              { notification_email: notification_email },
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Update Account Settings',
                  description: `Success`,
                  type: 'success',
                });
              }
            });
        });
    } catch (e) {
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onSaveMerchantSettings = (values) => {
    const company_name = values.company_name;
    const goverment_id = values.goverment_id;
    const company_address = values.company_address;
    const company_officer = values.company_officer;
    const verified_business = values.verified_business;
    try {
      firebaseHandler
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/account_settings`,
              {
                company_name: company_name,
                goverment_id: goverment_id,
                company_address: company_address,
                company_officer: company_officer,
                verified_business: verified_business,
              },
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Update Account Settings',
                  description: `Success`,
                  type: 'success',
                });
              }
            });
        });
    } catch (e) {
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  return (
    <Wrapper>
      <Row
        style={{
          marginTop: '5rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={!isDesktopOrLaptop ? 18 : 16}>
          <Collapse>
            <Panel header="Wallet Settings" key="1">
              <Card
                title="Receiving Wallet Address"
                extra={
                  !solanaWalletAddress && !accountIsLoading ? (
                    <Badge count={'Incomplete'} />
                  ) : null
                }
                loading={accountIsLoading}
              >
                <Form form={formWalletAddress} onFinish={onSaveWalletAddress}>
                  <Form.Item
                    name="solana_wallet_address"
                    label="Solana Address"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                  <Paragraph>
                    <Popconfirm
                      title="Are you sure to update your Solana wallet address?"
                      onConfirm={confirmUpdateSolanaWallAddress}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Link>Update</Link>
                    </Popconfirm>{' '}
                    address from your connected wallet.
                  </Paragraph>
                </Form>
              </Card>
              <Card title="Coin(s) to Accept" loading={accountIsLoading}>
                <Form form={formCoinsToAccept} onFinish={onSaveCoin}>
                  <Form.Item name="coins_to_accept">
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Divider plain>Stablecoins</Divider>
                      <Row>
                        <Col span={8}>
                          <Checkbox value="usdt">Tether (USDT)</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="usdc">USD Coin (USDC)</Checkbox>
                        </Col>
                      </Row>
                      <Divider plain>Non Stablecoins</Divider>
                      <Row>
                        <Col span={8}>
                          <Checkbox value="btc">Bitcoin (BTC)</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="eth">Ethereum (ETH)</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="sol">Solana (SOL)</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Panel>
            <Panel header="General User Settings" key="2">
              <Card style={{ marginTop: '15px' }} loading={accountIsLoading}>
                <Form
                  form={formUserSettings}
                  labelCol={{ span: 6 }}
                  onFinish={onSaveUserSettings}
                  requiredMark={false}
                  style={{ marginTop: '15px' }}
                >
                  <Form.Item label="Account ID" name="account_id">
                    <Text>{userId}</Text>
                  </Form.Item>
                  <Form.Item label="Account Email" name="account_email">
                    <Text>{userEmail}</Text>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Tooltip
                        title={`Your email to which payment notifications will be sent (for both merchants and users).`}
                      >
                        <u>Notification Email</u>
                      </Tooltip>
                    }
                    name="notification_email"
                  >
                    <Input
                      autoComplete="new-password"
                      type="email"
                      placeholder="Your email"
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Panel>
            <Panel header="Merchant Settings" key="3">
              <Card style={{ marginTop: '15px' }} loading={accountIsLoading}>
                <Form
                  form={formMerchantSettings}
                  labelCol={{ span: 6 }}
                  onFinish={onSaveMerchantSettings}
                  requiredMark={false}
                  style={{ marginTop: '15px' }}
                >
                  <Divider plain>Company Information</Divider>
                  <Form.Item label="Name" name="company_name">
                    <Input
                      autoComplete="new-password"
                      placeholder="Company Name"
                    />
                  </Form.Item>
                  <Form.Item label="Government ID" name="goverment_id">
                    <Input
                      autoComplete="new-password"
                      placeholder="Government issused ID"
                    />
                  </Form.Item>
                  <Form.Item label="Address" name="company_address">
                    <TextArea placeholder="Company address" rows={2} />
                  </Form.Item>
                  <Form.Item label="Officer" name="company_officer">
                    <Input
                      autoComplete="new-password"
                      placeholder="Officer name"
                    />
                  </Form.Item>
                  <Divider plain>Merchant Verification</Divider>
                  <Form.Item label="Verified Business" name="verified_business">
                    {isVerifiedBusiness ? (
                      <CheckCircleFilled style={{ color: '#1DA1F2' }} />
                    ) : (
                      <span>
                        <CloseCircleFilled
                          style={{
                            padding: '0px 5px',
                            verticalAlign: 'bottom',
                          }}
                        />
                        <Collapse style={{ marginTop: '15px' }}>
                          <Panel header="Info on verification" key="1">
                            <p>
                              To verify your business and get the blue
                              checkmark, please fill up the above merchant
                              section and{' '}
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://telegram.me/getbalancesoft"
                              >
                                Telegram message
                              </a>{' '}
                              us and send us
                            </p>
                            <ul>
                              <li>
                                1) Your Account Number:{' '}
                                <Text code>{userId}</Text>
                              </li>
                              <li>
                                2) Your company incoporation documentation
                              </li>
                              <li>
                                3) Recent 3 months bill / official letter
                                showing your company name and and address
                              </li>
                              <li>
                                4) Official identification documentation of the
                                company address
                              </li>
                            </ul>
                            <p>
                              Verified businesses{' '}
                              <CheckCircleFilled style={{ color: '#1DA1F2' }} />{' '}
                              provide users with greater assurance which leads
                              to more transactions.
                            </p>
                          </Panel>
                        </Collapse>
                      </span>
                    )}
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Wrapper>
  );
}
