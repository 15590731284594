import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message,
} from 'antd';
import { CopyBlock, dracula } from 'react-code-blocks';
import { POPULAR_FIAT, POPULAR_TOKENS } from '../utils/tokenTypes';
import {
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import apiClient from '../utils/apiClient';
import firebaseHandler from '../config/firebase.js';
import moment from 'moment';
import { notify } from '../utils/notifications';
import styled from 'styled-components';
import { useConnection } from '../utils/connection';
import uuid from 'uuid';

const { Option, OptGroup } = Select;
const { Paragraph, Text, Title } = Typography;
const { Search, TextArea } = Input;
const { Panel } = Collapse;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`;

export default function IsLoaded() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const unregisterAuthObserver = firebaseHandler
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver();
  }, []);
  return <>{isSignedIn ? <CreatePaymentRequestPage /> : null}</>;
}

function CreatePaymentRequestPage() {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) => (
        <Text code>
          <Link to={`/pay/${record.id}`}>{text}</Link>{' '}
        </Text>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',
      sorter: (a, b) => a.date_created.localeCompare(b.date_created),
      ellipsis: true,
      render: (text) => <Text code>{text}</Text>,
    },
    {
      title: 'Item Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Quantity',
      dataIndex: 'price_quantity',
      key: 'price_quantity',
      sorter: (a, b) => a.price_quantity - b.price_quantity,
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Price Token',
      dataIndex: 'price_token',
      key: 'price_token',
      sorter: (a, b) => a.price_token.localeCompare(b.price_token),
      ellipsis: true,
      render: (text) => (text ? <Text code>{text}</Text> : <Text code>-</Text>),
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Recurring',
          value: 'recurring',
        },
        {
          text: 'One Time',
          value: 'one_time',
        },
        {
          text: 'Fund Raising',
          value: 'fund_raising',
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (text, record) => (
        <span>
          <Tag>
            {text === 'one_time' ? 'One Time' : null}
            {text === 'recurring' ? 'Recurring' : null}
            {text === 'fund_raising' ? 'Fund Raising' : null}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <span>
          {record.type === 'one_time' ? (
            text === 'PAID' ? (
              <Tag color="green">Paid</Tag>
            ) : (
              <Tag color="orange">Unpaid</Tag>
            )
          ) : (
            <Tag>-</Tag>
          )}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Paragraph
            copyable={{
              text:
                process.env.NODE_ENV === 'development'
                  ? `${process.env.REACT_APP_SHARE_LINK_PREFIX_DEV}${record.id}`
                  : `${process.env.REACT_APP_SHARE_LINK_PREFIX_PROD}${record.id}`,
            }}
            style={{ marginBottom: 0 }}
          >
            Share
          </Paragraph>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              showModal('Edit', text, record, connection);
            }}
          >
            Edit
          </a>
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => confirmDelete(text, record)}
            okText="Yes"
            cancelText="No"
          >
            <a href="/">Delete</a>
          </Popconfirm>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setSnippetId(record.id);
              setIsCodeModalVisible(true);
            }}
          >
            Code
          </a>
        </Space>
      ),
    },
  ];

  const [accountData, setAccountData] = useState<any>(null);
  const [isLoadedAccount, setIsLoadedAccount] = useState(false);
  const [dataList, setDataList] = useState<any>([]);
  const [imageName, setImageName] = useState('');
  const [imageNameUrl, setImageNameUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Pay with Balancesoft');
  const [snippetId, setSnippetId] = useState(null);
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);
  const [modalType, setModalType] = useState('create');
  const [paymentType, setPaymentType] = useState('one_time');
  const connection = useConnection();

  const [form] = Form.useForm();
  const [searchFilterform] = Form.useForm();
  const [addOnBefore, setAddOnBefore] = useState('http://');
  const [priceToken, setPriceToken] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .get(`/account_settings`, {
              headers: { firebase_token: idToken },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setAccountData(responseObject?.data?.data);
                setIsLoadedAccount(true);
              }
            });
        });
    };
    fetchData();
    loadPaymentRequests();
    // eslint-disable-next-line
  }, []);

  const loadPaymentRequests = async () => {
    try {
      setDataList([]);
      setIsLoading(true);
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(
              `/payment_request/filter`,
              {},
              {
                headers: { firebase_token: idToken },
              },
            )
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setIsLoading(false);
                setDataList(responseObject?.data?.data);
                message.success('Data Loaded');
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  function confirmDelete(text, record) {
    let id = record.id;
    try {
      firebaseHandler!
        .auth()!
        .currentUser!.getIdToken(true)
        .then((idToken) => {
          apiClient
            .delete(`/payment_request/${id}`, {
              headers: { firebase_token: idToken },
            })
            .then((responseObject) => {
              if (responseObject.status === 200) {
                notify({
                  message: 'Payment Item',
                  description: 'Deleted',
                  type: 'success',
                });
                setDataList(
                  dataList.filter((item) => {
                    return item.id !== record.id;
                  }),
                );
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Payment Item',
        description: e.message,
        type: 'error',
      });
    }
  }

  async function showModal(type, text, record, connection) {
    setModalType(type);
    setIsModalVisible(true);
    setPaymentType(record?.type);
    setPriceToken(record?.price_token);

    if (imageNameUrl !== null || imageNameUrl !== undefined) {
      setImageNameUrl(record?.image_url);
    }

    form.setFieldsValue({
      type: record?.type,
      title: record?.title,
      price_token: record?.price_token,
      price_quantity: record?.price_quantity,
      date_due: record?.date_due ? record?.date_due : null,
      id: record?.id,
      memo: record?.memo,
      inventory: record?.inventory,
      required_info: record?.required_info,
      triggers: record?.triggers,
      public_key: accountData?.solana_wallet_address,
    });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmitModal = (values: any) => {
    values.type =
      values.type === null || values.type === undefined
        ? 'one_time'
        : values.type;

    if (typeof imageNameUrl !== 'undefined' && imageNameUrl != null) {
      values.image_url = imageNameUrl;
    }

    if (typeof priceToken !== 'undefined' && priceToken != null) {
      values.price_token = priceToken;
    }

    if (
      typeof values.redirect_link !== 'undefined' &&
      values.redirect_link != null
    ) {
      values.redirect_link = addOnBefore + values.redirect_link;
    }

    if (values.create_type == null) {
      values.create_type = 'web';
    }

    try {
      firebaseHandler!
        .auth()!
        .currentUser!.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(`/payment_request`, values, {
              headers: { firebase_token: idToken },
              params: { action: modalType.toLowerCase() },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                notify({
                  message: 'Instant payment',
                  description: `${modalType} Success`,
                  type: 'success',
                });
                setIsModalVisible(false);
                loadPaymentRequests();
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onSubmitFilterModal = (values: any) => {
    try {
      firebaseHandler!
        .auth()!
        .currentUser!.getIdToken(true)
        .then((idToken) => {
          apiClient
            .post(`/payment_request/filter`, values, {
              headers: { firebase_token: idToken },
              params: values,
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setDataList(responseObject?.data?.data);
                setIsSearchFilterModalVisible(false);
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const onIdSearch = (value) => {
    if (value === '') {
      return notify({
        message: 'Please provide request id',
        type: 'error',
      });
    }
    try {
      firebaseHandler
        ?.auth()
        ?.currentUser?.getIdToken(true)
        .then((idToken) => {
          apiClient
            .get(`/payment_request/filter`, {
              headers: { firebase_token: idToken },
              params: { id: value },
            })
            .then((responseObject) => {
              if (responseObject?.status === 200) {
                setIsLoading(false);
                setDataList(responseObject?.data?.data);
                setIsSearchModalVisible(false);
                message.success('Data Loaded');
              }
            });
        });
    } catch (_e) {
      let e: any = _e;
      notify({
        message: 'Error',
        description: e.message,
        type: 'error',
      });
    }
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    const storage = firebaseHandler.storage();
    const metadata = {
      contentType: file.type,
    };
    const storageRef = await storage.ref();
    const imageName = uuid.v4();
    const imgFile = storageRef.child(
      `payment_request_image/${imageName}.${file.name.split('.').pop()}`,
    );
    try {
      const image = await imgFile.put(file, metadata);
      onSuccess(null, image);
      imgFile.getDownloadURL().then((url) => {
        setImageName(imageName);
        setImageNameUrl(url);
      });
    } catch (e) {
      onError(e);
    }
  };

  // Search forms
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isSearchFilterModalVisible, setIsSearchFilterModalVisible] = useState(
    false,
  );

  const showSearchModal = () => {
    setIsSearchModalVisible(true);
  };

  const showSearchFilterModal = () => {
    setIsSearchFilterModalVisible(true);
  };

  const handleSearchCancel = () => {
    setIsSearchModalVisible(false);
  };

  const handleSearchFilterCancel = () => {
    setIsSearchFilterModalVisible(false);
  };

  return (
    <Wrapper>
      <Modal
        title={
          <span style={{ textTransform: 'capitalize' }}>
            {modalType} Crypto Payment Request
          </span>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={'50rem'}
      >
        <Form
          {...formItemLayout}
          form={form}
          requiredMark={false}
          onFinish={onSubmitModal}
          initialValues={{ public_key: accountData?.solana_wallet_address }}
        >
          <Form.Item label="Receiving Wallet">
            <Input
              disabled
              readOnly
              value={accountData?.solana_wallet_address}
            />
          </Form.Item>
          {modalType.toLowerCase() === 'edit' ? (
            <Form.Item name="id" label="Id">
              <Input disabled style={{ cursor: 'text', width: '100%' }} />
            </Form.Item>
          ) : null}
          <Form.Item name="type" label="Payment Type">
            <Select defaultValue="one_time" onChange={(e) => setPaymentType(e)}>
              <Option value="one_time">One-Time (Invoice)</Option>
              <Option value="recurring">
                Multiple Payments (Fixed Price, Ecommerce)
              </Option>
              <Option value="fund_raising">
                Multiple Payments (User to Input Price, Fund Raising)
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="title" label={`Item Title`}>
            <Input autoComplete="off" placeholder="Optional" />
          </Form.Item>

          {paymentType === 'fund_raising' ? null : (
            <Form.Item
              name="price_quantity"
              label="Price"
              rules={[
                { required: true, message: 'Price is required' },
                {
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: 'Price should be bigger than 0',
                },
              ]}
            >
              <Input
                autoComplete="off"
                type="number"
                addonBefore={
                  <Select
                    className="select-before"
                    style={{ width: '10rem' }}
                    onChange={(e) => setPriceToken(e)}
                    value={priceToken}
                  >
                    <OptGroup label="Crypto">
                      {POPULAR_TOKENS.map((i) => (
                        <React.Fragment key={i.mintAddress}>
                          {
                            <Option key={i.mintAddress} value={i.tokenSymbol}>
                              <Avatar
                                src={
                                  <Image
                                    preview={false}
                                    style={{ width: '1rem' }}
                                    src={i.icon}
                                  />
                                }
                              />
                              &nbsp;
                              {i.tokenSymbol}
                            </Option>
                          }
                        </React.Fragment>
                      ))}
                    </OptGroup>
                    <OptGroup label="Fiat">
                      {POPULAR_FIAT.map((i) => (
                        <React.Fragment key={i.mintAddress}>
                          {
                            <Option key={i.code} value={i.code}>
                              <Avatar
                                src={
                                  <Image
                                    preview={false}
                                    style={{ width: '1rem' }}
                                    src={i.icon}
                                  />
                                }
                              />
                              &nbsp;
                              {i.code}
                            </Option>
                          }
                        </React.Fragment>
                      ))}
                    </OptGroup>
                  </Select>
                }
              />
            </Form.Item>
          )}

          {paymentType === 'recurring' ? (
            <Form.Item name="inventory" label="Inventory">
              <InputNumber
                min={1}
                placeholder="Optional"
                style={{ minWidth: '5rem' }}
              />
            </Form.Item>
          ) : null}

          <Collapse>
            <Panel header="More Details" key="1">
              <Form.Item label="Item Picture">
                <Upload
                  accept=".jpg,.png"
                  onChange={(event) => {
                    if (event?.file?.status === 'removed') {
                      setImageName('');
                    }
                  }}
                  customRequest={uploadImage}
                >
                  {imageName === '' ? (
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  ) : null}
                </Upload>
                {imageNameUrl !== '' ? (
                  <span>
                    <Image
                      width={100}
                      src={imageNameUrl}
                      style={{ marginTop: '15px' }}
                    />
                    <br />
                    <Button
                      size="small"
                      onClick={() => {
                        setImageNameUrl('');
                        setImageName('');
                      }}
                    >
                      Remove
                    </Button>
                  </span>
                ) : null}
              </Form.Item>
              <Form.Item name="memo" label="Message to User">
                <TextArea rows={2} placeholder="Optional" autoComplete="off" />
              </Form.Item>
              {paymentType !== 'recurring' || paymentType === undefined ? (
                <Form.Item name="date_due" label="Due">
                  <DatePicker
                    disabledDate={(current) => {
                      return moment().add(-1, 'days') >= current;
                    }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              ) : null}
              <Form.Item
                name="required_info"
                label={
                  <Tooltip
                    title={`Required info from user to fulfill the order.`}
                  >
                    <u>Required Info from User</u>
                  </Tooltip>
                }
              >
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="shipping_address">
                        Shipping Address
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="user_name">Name</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="user_email">Email</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="user_message">
                        Ask User to Leave Message
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="redirect_link"
                label={
                  <Tooltip title={`URL to redirect on successful payment.`}>
                    <u>Success Redirect</u>
                  </Tooltip>
                }
              >
                <Input
                  addonBefore={
                    <Select
                      defaultValue="http://"
                      className="select-before"
                      onChange={(e) => setAddOnBefore(e)}
                    >
                      <Option value="http://">http://</Option>
                      <Option value="https://">https://</Option>
                    </Select>
                  }
                  placeholder="Optional URL"
                />
              </Form.Item>
            </Panel>
          </Collapse>
          <Form.Item {...tailLayout} style={{ marginTop: '15px' }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Search By Payment Request ID"
        visible={isSearchModalVisible}
        footer={null}
        onCancel={handleSearchCancel}
      >
        <Search
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          allowClear
          enterButton="Search"
          onSearch={onIdSearch}
        />
      </Modal>
      <Modal
        title="Search With Filter"
        visible={isSearchFilterModalVisible}
        footer={null}
        onCancel={handleSearchFilterCancel}
      >
        <Form
          {...formItemLayout}
          form={searchFilterform}
          requiredMark={false}
          onFinish={onSubmitFilterModal}
        >
          {modalType.toLowerCase() === 'edit' ? (
            <Form.Item name="id" label="Id">
              <Input disabled style={{ cursor: 'text', width: '100%' }} />
            </Form.Item>
          ) : null}
          <Form.Item name="type" label="Payment Type">
            <Select defaultValue="all" onChange={(e) => setPaymentType(e)}>
              <Option value="all">All</Option>
              <Option value="one_time">One-Time</Option>
              <Option value="recurring">Recurring</Option>
              <Option value="fund_raising">
                Multiple Payments (User to Input Price, Fund Raising)
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="price_token" label="Price Token">
            <Select defaultValue="all" allowClear>
              <Option key="all" value="all">
                All
              </Option>
              <OptGroup label="Crypto">
                {POPULAR_TOKENS.map((i) => (
                  <React.Fragment key={i.mintAddress}>
                    {
                      <Option key={i.mintAddress} value={i.tokenSymbol}>
                        <Avatar
                          src={
                            <Image
                              preview={false}
                              style={{ width: '1rem' }}
                              src={i.icon}
                            />
                          }
                        />
                        &nbsp;
                        {i.tokenSymbol}
                      </Option>
                    }
                  </React.Fragment>
                ))}
              </OptGroup>
              <OptGroup label="Fiat">
                {POPULAR_FIAT.map((i) => (
                  <React.Fragment key={i.mintAddress}>
                    {
                      <Option key={i.code} value={i.code}>
                        <Avatar
                          src={
                            <Image
                              preview={false}
                              style={{ width: '1rem' }}
                              src={i.icon}
                            />
                          }
                        />
                        &nbsp;
                        {i.code}
                      </Option>
                    }
                  </React.Fragment>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="created_source" label="Created Source">
            <Select defaultValue="all">
              <Option value="all">All</Option>
              <Option value="web">Web</Option>
              <Option value="api">API</Option>
            </Select>
          </Form.Item>
          <Form.Item name="paid_status" label="Paid Status">
            <Select defaultValue="all" onChange={(e) => setPaymentType(e)}>
              <Option value="all">All</Option>
              <Option value="paid">Paid</Option>
              <Option value="unpaid">Unpaid</Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  searchFilterform.resetFields();
                }}
              >
                Reset
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        footer={null}
        title="Code Snippet"
        visible={isCodeModalVisible}
        onCancel={() => setIsCodeModalVisible(false)}
      >
        <p>
          Default Balancesoft button for the payment request{' '}
          <Text code>{snippetId}</Text>. Copy and paste the html code to your
          site. You may adjust the styling to suit your site.
        </p>
        <Form.Item
          label="Button Label"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Select
            defaultValue="Pay with Balancesoft"
            onChange={(e) => setButtonLabel(e)}
          >
            <Option value="Pay with Balancesoft">Pay with Balancesoft</Option>
            <Option value="Tip Me with Balancesoft">
              Tip Me with Balancesoft
            </Option>
            <Option value="Balancesoft Pay">Balancesoft Pay</Option>
          </Select>
        </Form.Item>
        <CopyBlock
          text={`<button style="background: #fd9251; border-color: #fd9251; border-radius: 2px; border: 1px solid transparent; color: white; cursor: pointer; outline: 0; padding: 4px 15px;" onclick="window.open('${
            process.env.NODE_ENV === 'development'
              ? `${process.env.REACT_APP_SHARE_LINK_PREFIX_DEV}${snippetId}`
              : `${process.env.REACT_APP_SHARE_LINK_PREFIX_PROD}${snippetId}`
          }','_blank')">${buttonLabel}</button>`}
          language={'html'}
          theme={dracula}
          wrapLines
        />
        <Divider />
        <p style={{ display: 'flex', justifyContent: 'center' }}>
          Live preview of code snippet:{' '}
        </p>
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          dangerouslySetInnerHTML={{
            __html: `<button style="background: #fd9251; border-color: #fd9251; border-radius: 2px; border: 1px solid transparent; color: white; cursor: pointer; outline: 0; padding: 4px 15px;" onclick="window.open('${
              process.env.NODE_ENV === 'development'
                ? `${process.env.REACT_APP_SHARE_LINK_PREFIX_DEV}${snippetId}`
                : `https://${process.env.REACT_APP_SHARE_LINK_PREFIX_PROD}${snippetId}`
            }','_blank')">${buttonLabel}</button>`,
          }}
        />
      </Modal>
      <Title level={3}>Payment Request</Title>
      {!accountData?.solana_wallet_address && isLoadedAccount ? (
        <Text type="warning">
          <Link to={`/account-settings`}>
            <Text strong underline type="warning">
              Sync
            </Text>
          </Link>{' '}
          your Solana wallet address to Balancesoft under account settings
          before creating your first payment request.
        </Text>
      ) : null}
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Text strong>Payments Data</Text>
          <Button icon={<SearchOutlined />} onClick={showSearchModal}>
            Id
          </Button>
          <Button icon={<SearchOutlined />} onClick={showSearchFilterModal}>
            Filter
          </Button>
        </Space>
        <Space style={{ marginBottom: 15, marginTop: 15 }}>
          <Button
            disabled={accountData?.solana_wallet_address == null}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              showModal('Create', null, null, connection);
              form.resetFields();
            }}
          >
            Create Request
          </Button>
          <Button
            disabled={accountData?.solana_wallet_address == null}
            icon={<SyncOutlined />}
            onClick={() => {
              loadPaymentRequests();
            }}
          >
            Refresh
          </Button>
        </Space>
      </span>
      <Table
        columns={columns}
        dataSource={dataList}
        scroll={{ x: 1500 }}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              {record.date_created ? (
                <span>
                  Date Created: <Text code>{record.date_created || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.date_due ? (
                <span>
                  Date Due: <Text code>{record.date_due}</Text>
                  <br />
                </span>
              ) : null}
              {record.date_paid && !record.recurring_payments ? (
                <span>
                  Date Paid: <Text code>{record.date_paid}</Text>
                  <br />
                </span>
              ) : null}
              {record.id ? (
                <span>
                  Invoice Id: <Text code>{record.id || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.memo ? (
                <span>
                  Memo: <Text code>{record.memo || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.price_token ? (
                <span>
                  Price Token: <Text code>{record.price_token || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.price_quantity ? (
                <span>
                  Price Quantity:{' '}
                  <Text code>{record.price_quantity || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.required_info ? (
                <span>
                  Required Info:{' '}
                  <Text code>
                    {JSON.stringify(record.required_info) || '-'}
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.triggers ? (
                <span>
                  Triggers:{' '}
                  <Text code>{JSON.stringify(record.triggers) || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.status ? (
                <span>
                  Status: <Text code>{record.status || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.title ? (
                <span>
                  Title: <Text code>{record.title || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.id ? (
                <span>
                  Id: <Text code>{record.id || '-'}</Text>
                  <br />
                </span>
              ) : null}
              {record.txid ? (
                <span>
                  Paid Txid:{' '}
                  <Text code>
                    <a
                      href={'https://explorer.solana.com/tx/' + record.txid}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {record.txid || '-'}
                    </a>
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.type ? (
                <span>
                  Type:{' '}
                  <Text code style={{ textTransform: 'capitalize' }}>
                    {record.type.replace('_', ' ') || '-'}
                  </Text>
                  <br />
                </span>
              ) : null}
              {record.image_url ? (
                <span style={{ display: 'flex' }}>
                  Image:&nbsp;
                  {record.image_url ? (
                    <Image width={100} src={record.image_url} />
                  ) : (
                    '-'
                  )}
                </span>
              ) : null}

              {record.recurring_payments ? (
                <span>
                  User Payments:
                  <br />
                  {record.recurring_payments.map((item, key) => (
                    <span key={key}>
                      <span>
                        {' '}
                        <Text underline>({key})Txid: </Text>
                        <Text code>
                          {' '}
                          <a
                            href={'https://explorer.solana.com/tx/' + item.txid}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.txid}
                          </a>
                        </Text>
                        <br />
                      </span>
                      {item.user_name ? (
                        <span>
                          User Name: <Text code>{item.user_name}</Text>
                        </span>
                      ) : null}
                      {item.date_paid ? (
                        <span>
                          Date Paid: <Text code>{item.date_paid}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.shipping_address ? (
                        <span>
                          Shipping Address:{' '}
                          <Text code>{item.shipping_address}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.payer_wallet ? (
                        <span>
                          Payer Wallet: <Text code>{item.payer_wallet}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.user_email ? (
                        <span>
                          User Email: <Text code>{item.user_email}</Text>
                          <br />
                        </span>
                      ) : null}
                      {item.user_message ? (
                        <span>
                          User Message: <Text code>{item.user_message}</Text>
                          <br />
                        </span>
                      ) : null}
                    </span>
                  ))}
                </span>
              ) : null}
            </p>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        loading={isLoading}
        rowKey="id"
      />
    </Wrapper>
  );
}
