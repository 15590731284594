import { ENDPOINTS, useConnectionConfig } from '../utils/connection';
import { Menu, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Connection } from '@solana/web3.js';
import CustomClusterEndpointDialog from './CustomClusterEndpointDialog';
import { EndpointInfo } from '../utils/types';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import WalletConnect from './WalletConnect';
import firebase from 'firebase';
import firebaseHandler from '../config/firebase';
import logo from '../assets/logo-balancesoft.jpg';
import { notify } from '../utils/notifications';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const { SubMenu } = Menu;

const Wrapper = styled.div`
  background-color: #0d1017;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 30px;
  flex-wrap: wrap;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fd9251;
  font-weight: bold;
  cursor: pointer;
  img {
    height: 30px;
    margin-right: 8px;
  }
`;

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

export default function TopBar() {
  const {
    endpointInfo,
    setEndpoint,
    availableEndpoints,
    setCustomEndpoints,
  } = useConnectionConfig();

  const [addEndpointVisible, setAddEndpointVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const history = useHistory();

  useEffect(() => {
    firebaseHandler.auth().onAuthStateChanged((user) => setIsSignedIn(!!user));
  }, []);

  const onAddCustomEndpoint = (info: EndpointInfo) => {
    const existingEndpoint = availableEndpoints.some(
      (e) => e.endpoint === info.endpoint,
    );
    if (existingEndpoint) {
      notify({
        message: `An endpoint with the given url already exists`,
        type: 'error',
      });
      return;
    }

    const handleError = (e) => {
      console.log(`Connection to ${info.endpoint} failed: ${e}`);
      notify({
        message: `Failed to connect to ${info.endpoint}`,
        type: 'error',
      });
    };

    try {
      const connection = new Connection(info.endpoint, 'recent');
      connection
        .getEpochInfo()
        .then((result) => {
          setTestingConnection(true);
          console.log(`testing connection to ${info.endpoint}`);
          const newCustomEndpoints = [
            ...availableEndpoints.filter((e) => e.custom),
            info,
          ];
          setEndpoint(info.endpoint);
          setCustomEndpoints(newCustomEndpoints);
        })
        .catch(handleError);
    } catch (e) {
      handleError(e);
    } finally {
      setTestingConnection(false);
    }
  };

  const endpointInfoCustom = endpointInfo && endpointInfo.custom;
  useEffect(() => {
    const handler = () => {
      if (endpointInfoCustom) {
        setEndpoint(ENDPOINTS[0].endpoint);
      }
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [endpointInfoCustom, setEndpoint]);

  const handleClick = useCallback(
    (e) => {
      history.push(e.key);
    },
    [history],
  );

  return (
    <>
      <CustomClusterEndpointDialog
        visible={addEndpointVisible}
        testingConnection={testingConnection}
        onAddCustomEndpoint={onAddCustomEndpoint}
        onClose={() => setAddEndpointVisible(false)}
      />
      <Wrapper style={{ padding: '0px 15px' }}>
        <LogoWrapper onClick={() => history.push('/')}>
          <img src={logo} alt="" />
          {'Balancesoft'}
        </LogoWrapper>
        <Menu
          mode="horizontal"
          onClick={handleClick}
          style={{
            borderBottom: 'none',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'flex-end',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          {!isSignedIn ? null : (
            <SubMenu key="merchant" title="Merchant">
              <Menu.Item key="/create-payment">
                Manage Payments (Money In)
              </Menu.Item>
              <Menu.Item key="/smart-contracts">Smart Contracts</Menu.Item>
            </SubMenu>
          )}
          {!isSignedIn ? null : (
            <SubMenu key="user" title="User">
              <Menu.Item key="/track-payments-made">
                Track Payments Made
              </Menu.Item>
            </SubMenu>
          )}
          {!isSignedIn ? (
            <Menu.Item key={'/#'} onClick={() => setIsLoginModalVisible(true)}>
              Log In
            </Menu.Item>
          ) : null}
          {!isSignedIn ? null : (
            <SubMenu key="account" title="Settings">
              <Menu.Item key="/account-settings">Account Settings</Menu.Item>
              <Menu.Item key={'/developer'} title="Developer">
                Developer Settings
              </Menu.Item>
              <Menu.Item
                key={'/'}
                onClick={() => {
                  firebaseHandler.auth().signOut();
                  setIsSignedIn(false);
                }}
              >
                Log Out
              </Menu.Item>
            </SubMenu>
          )}
          <Menu.Item key="/" disabled style={{ cursor: 'pointer' }}>
            <WalletConnect />
          </Menu.Item>
        </Menu>
      </Wrapper>
      <Modal
        footer={null}
        title="Log In to Balancesoft"
        visible={isLoginModalVisible}
        onCancel={() => setIsLoginModalVisible(false)}
      >
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebaseHandler.auth()}
        />
      </Modal>
    </>
  );
}
